<template>
<div class="teacher-follow">
  <div class="teacher-follow-item" v-for="item in dataList" :key="item.id">
    <div class="teacher-follow-item-tit">{{ item.typeName }}  <span>|</span> {{ item.createTime }}   <span>|</span>
      {{ item.creator.realname || item.creator.nickname }}（ID{{ item.user_id }}）</div>
    <div class="teacher-follow-item-cont">{{item.content}}</div>
  </div>
  <div class="page" v-if="dataList.length">
    <MyPage :total="total" :current="page" @changePage="changePage" @changePageSize="changePageSize"></MyPage>
  </div>
  <div v-if="!dataList.length" class="no-data">
    <img src="../../../assets/images/new_icon/nodata.png" width="158" height="82" />
    <p>还没有数据～</p>
  </div>
</div>
</template>

<script>
import util from '@/utils/tools.js';
export default {
  name: "follow",
  data(){
    return{
      page:1,
      pageSize:10,
      total:0,
      dataList:[],
    }
  },
  props:{
    info:{
      type:Object,
      default:()=>{
        return {}
      }
    },
    followTypes:{
      type:Array,
      default:()=>{
        return []
      }
    },
    followStatus:{
      type:Boolean,
      default:false
    }
  },
  watch:{
    followStatus:{
      handler(newV){
        if(newV){
          this.getList();
        }
      },
      immediate:true
    }
  },
  created(){
    this.getList();
  },
  methods:{
    getList(){
      let params = {
        page:this.page,
        pageSize:this.pageSize,
        user_id:this.info.user.id
      };
      this.api.user.teacherFollowList(params).then((res)=>{
        this.dataList = res.list;
        this.dataList.forEach((item)=>{
          item.createTime = util.timeFormatter(new Date(+item.created_at*1000), 'yyyy.MM.dd hh:mm');
          let typeName = this.followTypes.filter((sItem)=>{
            return sItem.id == item.type;
          })[0].name;
          console.log(typeName,'typeNametypeNametypeName')
          this.$set(item,'typeName',typeName)
        })
        this.total = Number(res.count);
      })
    },
    changePage(page){
      this.page = page;
      this.getList();
    },
    changePageSize(pageSize){
      this.page = 1;
      this.pageSize = pageSize;
      this.getList();
    }
  }
}
</script>

<style scoped lang="scss">
.teacher-follow{
  .teacher-follow-item{
    margin-top: 20px;
    padding: 20px;
    background: #F6F7FA;
    border-radius: 6px;

    .teacher-follow-item-tit{
      margin-bottom: 6px;
      color: #666666;
      >span{
        padding: 0 20px;
      }
    }
    .teacher-follow-item-cont{
      color: #333333;
      line-height: 20px;
    }
  }
  .page{
    margin-top: 20px;
    text-align: right;
  }
  .no-data{
    width: 100%;
    height: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    >p{
      margin-top: 35px;
      font-size: 14px;
      font-weight: 400;
      color: #666666;
    }
  }
}
</style>
