<template>
  <el-dialog
      :visible.sync="modal"
      title="添加跟进信息"
      :z-index="2002"
      @closed="cancel"
      width="500px"
      :modal="false"
  >
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
      <el-form-item label="跟进方式" prop="type" >
        <el-select v-model="ruleForm.type" placeholder="请选择" style="width: 100%">
          <el-option
              v-for="item in followTypes"
              :key="item.id"
              :label="item.name"
              :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="跟进内容" prop="content">
        <el-input v-model="ruleForm.content" type="textarea" :rows="2" placeholder="请输入内容"></el-input>
      </el-form-item>
    </el-form>
    <div slot="footer">
      <el-button @click="cancel">取消</el-button>
      <el-button type="primary" @click="confirm">确定</el-button>
    </div>
  </el-dialog>

</template>

<script>
export default {
  name: "createFollow",
  data(){
    return{
      modal:true,
      ruleForm: {
        type:'',
        content:'',
      },
      rules: {
        type: [
          { required: true, message: '请选择跟进方式', trigger: 'change' },
        ],
        content: [
          { required: true, message: '请输入跟进内容', trigger: 'blur' }
        ],
      },
      options: [{
        value: '选项1',
        label: '黄金糕'
      }, {
        value: '选项2',
        label: '双皮奶'
      }, {
        value: '选项3',
        label: '蚵仔煎'
      }, {
        value: '选项4',
        label: '龙须面'
      }, {
        value: '选项5',
        label: '北京烤鸭'
      }],
      value:'',
      status:false,
    }
  },
  props:{
    followTypes:{
      type:Array,
      default:()=>{
        return []
      }
    },
    userId:{
      type:String,
      default:''
    }
  },
  watch:{
    followTypes:{
      handler(newV){
        console.log(newV,'newVnewVnewVnewVnewVnewV')
      },
      immediate:true
    }
  },
  methods:{
    confirm(){
      this.$refs['ruleForm'].validate((valid) => {
        if (valid) {
          if(this.status) return;
          // this.modal = false;
          let params = {
            type:this.ruleForm.type,
            follow_user_id:this.userId,
            content:this.ruleForm.content
          };
          this.status = true;
          this.api.user.teacherFollowCreate(params).then((res)=>{
            this.status = false;
            this.$Message.success('跟进成功');
            this.$emit('success')
          })

        }
      });
    },
    cancel(){
      // this.modal = false;
      this.$emit('close')
    }
  }
}
</script>

<style scoped lang="scss">
.create-follow{

}
</style>
<style>
.el-select-dropdown{
  z-index: 2020 !important;
}
</style>
