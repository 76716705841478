<template>
<div class="teacher-detail">
  <div>
    <div class="teacher-detail-top">
      <div class="btns">
        <div class="black" @click="dele">删除</div>
        <div class="mr0 red" @click="addBlack">加入黑名单</div>
        <p></p>
        <div class="black" @click="edit">编辑资料</div>
        <div @click="downAll">下载全部资料</div>
        <div @click="addFollow">+添加跟进</div>
      </div>
      <div>
        <img src="../../../assets/images/new_icon/guanbi.png" width="15" height="15" @click="closeDrawer" />
      </div>
    </div>
    <div class="teacher-info">
      <img :src="detail.avatar" width="70" height="98" />
      <div class="teacher-info-nr">
        <div class="teacher-info-name">{{ detail.user && (detail.user.realname || detail.user.nickname) }}</div>
        <div class="teacher-info-time" v-if="timeArr.length">
          <p v-for="(item,index) in timeArr" :key="index">{{ item }}</p>
        </div>
        <div class="teacher-info-list">
          <div>
            <img src="../../../assets/images/new_icon/dizhi.png" width="13" height="16" />
            <p>{{ city }} </p>
          </div>
          <div>
            <img src="../../../assets/images/new_icon/xueli.png" width="18" height="16" />
            <p>{{ degree }} </p>
          </div>
          <div v-if="detail.user && detail.user.mobile">
            <img src="../../../assets/images/new_icon/dianhua.png" width="12" height="16" />
            <p>{{ detail.user.mobile }} </p>
          </div>
          <div v-if="detail.weixin">
            <img src="../../../assets/images/new_icon/wx.png" width="20" height="16" />
            <p>{{ detail.weixin }} </p>
          </div>
        </div>
      </div>
    </div>
    <div class="teacher-tab">
      <div :class="curTab == item.id ? 'active' : ''" v-for="item in tabList" :key="item.id" @click="changeTab(item)">{{ item.name }}</div>
    </div>
  </div>
  <div class="teacher-bot">
    <Info v-if="curTab == 1" :info="info" @showCert="showCert"></Info>
    <Follow v-if="curTab == 2" :info="info" :followTypes="followTypes" :followStatus="followStatus"></Follow>
  </div>
  <DeleModal :status="deleModalStatus" txt="确认删除吗" :loading="modalLoading" @cancel="cancelDele" @confirm="confirmDele"></DeleModal>
  <div v-if="createFollowStatus">
    <CreateFollow @success="followSuccess" @close="followClose" :followTypes="followTypes" :userId="info.user.id"></CreateFollow>
  </div>

</div>
</template>

<script>
import Info from './info'
import Follow from './follow'
import DeleModal from '../../../components/deleteModal.vue';
import FileSaver from "file-saver";
import CreateFollow from './createFollow'
import util from '@/utils/tools.js';
export default {
  name: "detail",
  data(){
    return {
      tabList:[
        {
          name:'详细信息',
          id:1
        },
        {
          name:'跟进合作',
          id:2
        }
      ],
      curTab:1,
      detail:{},
      timeArr:[],
      degree:'',
      city:'',
      deleModalStatus:false,
      modalLoading:false,
      createFollowStatus:false,
      followStatus:false,
    }
  },
  props:{
    info:{
      type:Object,
      default:()=>{
        return {}
      }
    },
    degreeList:{
      type:Array,
      default:()=>{
        return []
      }
    },
    followTypes:{
      type:Array,
      default:()=>{
        return []
      }
    }
  },
  components:{
    Info,
    Follow,
    DeleModal,
    CreateFollow
  },
  watch:{
    info:{
      handler(newV){
        if(newV){
          this.init(newV);
        }
      },
      immediate:true,
    }
  },
  created(){

  },
  methods:{
    init(data){
      this.detail = JSON.parse(JSON.stringify(data));
      this.timeArr = [];
      for(let name in this.detail.time_manage_arr){
        this.timeArr.push(this.detail.time_manage_arr[name])
      }
      this.degree = this.degreeList.filter((item)=>{
        return this.detail.degree == item.id;
      })[0].name;

      let cityArr = [];
      if(this.detail.province && this.detail.province.name){
        cityArr.push(this.detail.province.name)
      }
      if(this.detail.city && this.detail.city.name){
        cityArr.push(this.detail.city.name)
      }
      this.city = cityArr.join('-');
    },
    changeTab(data){
      this.curTab = data.id;
    },
    edit(){
      const routeUrl = this.$router.resolve({
        path:'/user/authentication',
        query:{
          id:this.detail.user.id,
          name:this.detail.user.nickname
        }
      })
      window.open(routeUrl.href, "_blank");
    },
    dele(){
      this.deleModalStatus = true;
    },
    confirmDele(){
      let params = {
        id:this.detail.id
      }
      this.api.user.userTeacherDelete(params).then((res)=>{
        this.$Message.success('删除成功');
        this.deleModalStatus = false;
        this.$emit('deleSuccess')
      })
    },
    cancelDele(){
      this.deleModalStatus = false;
    },
    addBlack(){
      let params = {
        id:this.detail.id
      }
      this.api.user.teacherSetBlacklist(params).then((res)=>{
        this.$Message.success('加入黑名单成功');
        this.$emit('opSuccess')
      })
    },
    downAll(){
      this.downLoad(this.detail.resume);
      if(this.detail.make_course.uploadUrl){
        this.downLoad(this.detail.make_course.uploadUrl);
      }

      let arr = this.detail.auth_cert.split(',');
      arr.forEach((item)=>{
        this.downLoad(item);
      })
    },
    downLoad(data){
      let arr = data.split('show/?file=');
      if(arr[1]){
        let uploadParams = {
          file:arr[1]
        };
        this.api.codekey.uploadfileDownload(uploadParams).then((res)=>{
          util.downLoad(res);
        })
      }else{
        let name = data.split('/');
        name = name[name.length -1].split(/\.(?=[^\.]+$)/);
        name = name[0] + '.' + name[1];
        FileSaver.saveAs(data,name);
      }
    },
    addFollow(){
      console.log(this.createFollowStatus,'this.createFollowStatus')
      this.followStatus = false;
      this.createFollowStatus = true;
    },
    followSuccess(){
      this.createFollowStatus = false;
      this.followStatus = true;
    },
    followClose(){
      this.createFollowStatus = false;
    },
    closeDrawer(){
      this.$emit('close')
    },
    showCert(data){
      console.log(data,'ddddd=========')
      this.$emit('showCert',data);
    },
  }
}
</script>

<style scoped lang="scss">
.teacher-detail{
  padding: 30px;
  font-size: 14px;
  display: flex;
  flex-direction: column;
  height: 100%;
  .teacher-detail-top{
    display: flex;
    justify-content: space-between;
    align-items: center;
    .btns{
      display: flex;
      justify-content: flex-start;
      align-items: center;
      >div{
        margin-right: 10px;
        padding: 0 10px;
        height: 28px;
        background: #5782FF;
        border-radius: 4px;
        color: #FFFFFF;
        display: flex;
        align-items: center;
        cursor: pointer;
      }
      >p{
        margin: 0 30px;
        width: 1px;
        height: 26px;
        background: #E8EAEC;
      }
      .mr0{
        margin-right: 0;
      }
      .black{
        background: #333333;
      }
      .red{
        background: #FC605C;
      }
    }
  }
  .teacher-info{
    margin-top: 20px;
    padding: 20px;
    background: #F6F7FA;
    border-radius: 6px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .teacher-info-nr{
      margin-left: 20px;

      .teacher-info-name{
        font-size: 18px;
        font-weight: bold;
        color: #333333;
        margin-bottom: 8px;
      }
      .teacher-info-time{
        //margin-top: 8px;
        //margin-bottom: 16px;
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        >p{
          margin-right: 6px;
          padding: 0 10px;
          height: 30px;
          background: #EDF1FC;
          border-radius: 4px;
          display: flex;
          align-items: center;
        }
      }
      .teacher-info-list{
        margin-top: 16px;
        display: flex;
        justify-content: flex-start;

        >div{
          margin-right: 24px;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          >img{
            margin-right: 6px;
          }
        }
      }
    }
  }
  .teacher-tab{
    margin-top: 30px;
    height: 34px;
    font-size: 16px;
    color: #666666;
    border-bottom: 1px solid #E1E1E1;
    display: flex;
    justify-content: flex-start;
    >div{
      margin-right: 40px;
      height: 100%;
      cursor: pointer;
    }
    .active{
      font-weight: bold;
      color: #4A79FF;
      border-bottom: 4px solid #5782FF;
    }
  }
  .teacher-bot{
    flex: 1;
    height: 0;
    overflow-y: auto;
  }
}
</style>
