<template>
  <div class="authentication">
    <el-drawer :visible.sync="drawerShow" size="80%" @close="$emit('close')">
      <div class="drawer-title" slot="title">
        <div class="drawer-title-text">师资认证</div>
        <div class="drawer-title-btns">
          <Button type="primary" style="margin-right: 10px" @click="showSaveModal">提交审核</Button>
          <Button @click="cancel">取消</Button>
        </div>
      </div>
      <div style="padding:20px;">
        <div class="authentication-sub-tit mt20">
          <span></span>
          <span>必填信息</span>
        </div>
        <div class="authentication-cont mt20">
          <div class="authentication-cont-item bor-bot" v-if="!isInfo">
            <p><span>*</span><span>选择用户</span></p>
            <div>
              <el-select v-model="userId" filterable remote :remote-method="(query) => getUserList(query)"
                style="width:300px;" class="company-h" :disabled="!!userName">
                <el-option v-for="item in userList" :key="item.id" :label="item.nickname" :value="item.id">
                </el-option>
              </el-select>
              <Button v-if="userId" type="primary" style="margin-left: 20px" @click="editUser">编辑</Button>
            </div>
          </div>
          <div>
            <div>你还没有关注“实战云”公众号，请扫描二维码关注:</div>
            <el-button type="primary" @click="goInfo(2)" class="open mt10">{{ weixinSubscribe == '1' ? '已关注' : '去关注'
            }}</el-button>
          </div>
        </div>
        <div class="authentication-cont">
          <div class="authentication-cont-tit">
            <span>*</span><span>擅长的技术方向（多选）</span>
          </div>
          <div class="authentication-cont-list">
            <div class="authentication-cont-list-add" @click="addDirection">
              <el-button type="primary" size="small">+ 技术方向添加</el-button>
            </div>
            <div class="authentication-cont-list-tag" v-for="(item, index) in selectDirectionData" :key="item.firstId">
              <span v-if="item.firstId != 99">{{ item.firstName }}<span v-if="item.secondName">-{{ item.secondName
              }}</span><span v-if="item.name">-{{ item.name }}</span></span>
              <span v-else>{{ item.firstName }}-{{ item.desc }}</span>
              <Icon type="md-close" @click="deleteDirectionTag(index)" />
            </div>
          </div>
        </div>
        <div class="authentication-cont">
          <div class="bor-bot">
            <div class="authentication-cont-tit">
              <span>*</span><span>擅长的技术体系（多选）</span>
            </div>
            <div class="authentication-cont-list">
              <p :class="item.status ? 'active' : ''" v-for="(item, index) in techSystem" :key="item.id"
                @click="changeItem(index, techSystem)">{{ item.name }}</p>
              <Input v-if="techSystem.length && techSystem[techSystem.length - 1].status" :maxlength="20"
                v-model="techSystemDes" placeholder="请输入内容" style="width: 300px;margin-bottom: 10px" />
            </div>
          </div>
          <div class="mt20 bor-bot">
            <div class="authentication-cont-tit">
              <span>*</span><span>您的经验有哪些？（多选）</span>
            </div>
            <div class="authentication-cont-list">
              <p :class="item.status ? 'active' : ''" v-for="(item, index) in workExp" :key="item.id"
                @click="changeItem(index, workExp)">{{ item.name }}</p>
              <Input v-if="workExp.length && workExp[workExp.length - 1].status" v-model="workExpDes" :maxlength="20"
                placeholder="请输入内容" style="width: 300px;margin-bottom: 10px" />
            </div>
          </div>
          <div class="mt20 bor-bot">
            <div class="authentication-cont-tit">
              <span>*</span><span>常驻地</span>
            </div>
            <el-cascader :options="cityList" v-model="cityId" :props="optionProps" clearable
              style="width: 300px;"></el-cascader>
          </div>
          <div class="mt20 bor-bot">
            <div class="authentication-cont-tit">
              <span>*</span><span>微信号</span>
            </div>
            <el-input v-model="weixin" placeholder="请输入微信号" style="width: 300px" :maxlength="20" />
          </div>
          <div class="mt20">
            <div class="authentication-cont-tit">
              <span>*</span><span>最高学历</span>
            </div>
            <div class="authentication-cont-list">
              <p :class="item.status ? 'active' : ''" v-for="(item, index) in degree" :key="item.id"
                @click="changeRadioItem(index, degree)">{{ item.name }}</p>
            </div>
          </div>
        </div>
        <div class="authentication-cont">
          <div class="authentication-cont-tit mb10">
            <span>*</span><span>工作经历</span>
          </div>
          <div class="exp-list">
            <div class="exp-list-item" v-for="(item, index) in job_exp" :key="item.id" @click="editWork(item, index)">
              <img src="../../../assets/images/new_icon/guanbi.png" width="12" height="12" class="exp-list-item-close"
                @click.stop="deleteWork(index)" />
              <div class="exp-list-item-tit">{{ item.dateStr }}</div>
              <div class="exp-list-item-info">{{ item.companyName }}（{{ item.position }}）</div>
            </div>
          </div>
          <el-button type="primary" @click="addWork">+ 添加工作经历</el-button>
        </div>
        <div class="authentication-cont">
          <div class="authentication-cont-tit mb10">
            <span>*</span><span>培训/授课经历</span>
          </div>
          <div class="exp-list">
            <div class="exp-list-item" v-for="(item, index) in training_exp" :key="item.id"
              @click="editProject(item, index)">
              <img src="../../../assets/images/new_icon/guanbi.png" width="12" height="12" class="exp-list-item-close"
                @click.stop="deleteProject(index)" />
              <div class="exp-list-item-tit">{{ item.dateStr }}</div>
              <div class="exp-list-item-info">{{ item.cont }}</div>
            </div>
          </div>
          <el-button type="primary" @click="addProject">+ 添加培训/授课经历</el-button>
        </div>
        <div class="authentication-cont">
          <div class="authentication-cont-tit mb10">
            <span>*</span><span>主讲课程</span>
          </div>
          <el-input type="textarea" :rows="2" placeholder="请输入内容" v-model="main_course" style="width: 100%">
          </el-input>
        </div>
        <div class="authentication-cont">
          <div class="bor-bot">
            <div class="authentication-cont-tit">
              <span>*</span><span>所获资质及证书</span>
            </div>
            <div class="authentication-cont-list">
              <div class="authentication-cont-list-add" @click="addCategory">
                <el-button type="primary" size="small">+ 资质添加</el-button>
              </div>
              <div class="authentication-cont-list-add" @click="noCategory">
                <el-button size="small">暂无资质</el-button>
              </div>
            </div>
            <div class="authentication-cont-list">
              <div class="authentication-cont-list-tag" v-for="(item, index) in selectCategoryData" :key="item.name">
                <span>{{ item.categoryName }}-{{ item.secondCategoryName }}-{{ item.name }}</span>
                <Icon type="md-close" @click="deleteTag(index)" />
              </div>
            </div>
            <div class="authentication-cont-list" v-if="noCategoryData.length && !selectCategoryData.length">
              <div class="authentication-cont-list-tag">
                <span>暂无资质</span>
              </div>
            </div>
          </div>
          <div class="mt20">
            <div class="authentication-cont-tit">
              <span></span><span>技术认证相关证书</span>
            </div>
            <div class="authentication-cont-list">
              <div class="certificate-list">
                <div class="certificate-list-item" v-for="item in certificateList" :key="item.url">
                  <img :src="item.url">
                  <div class="certificate-list-item-icon">
                    <Icon type="ios-eye-outline" @click.native="handleView(item.url)"></Icon>
                    <Icon type="ios-trash-outline" @click.native="handleRemove(item)"></Icon>
                  </div>
                </div>
              </div>
              <Upload type="drag" :action="constant.URL + '/uploadfile/upload'" :headers="{
                'access-token': Cookies.get(constant.tokenName)
              }" :data="{
  type: 0
}" accept=".jpg,.jpeg,.png" :format="['jpg', 'jpeg', 'png']" :on-success="certificateFileSuccess"
                :show-upload-list="false" :on-format-error="formatError" :on-exceeded-size="formatError" :max-size="5120"
                multiple>
                <div>
                  <Icon type="ios-add" :size="70" />
                </div>
              </Upload>
            </div>
          </div>
        </div>
        <div class="authentication-cont">
          <div class="authentication-cont-tit">
            <span>*</span><span>个人简历</span>
          </div>
          <div class="authentication-cont-list">
            <Upload type="drag" :action="constant.URL + '/uploadfile/upload'" :headers="{
              'access-token': Cookies.get(constant.tokenName)
            }" :data="{
  type: 0
}" accept=".pdf" :format="['pdf']" :on-success="fileSuccess" :show-upload-list="false" :on-format-error="formatError"
              class="upload-cont">
              <div class="upload-cont-info">

                <div class="upload-cont-info-message">
                  <div class="resume">
                    <Button type="primary" icon="ios-cloud-upload-outline" style="width: 80%;margin-bottom: 20px">{{
                      fileName ? fileName : '上传文件' }}</Button>
                    <p class="preview" @click="checkResume(resumeUrl)">预览</p>
                  </div>

                  <p>点击按钮选择试题文件，或拖拽试题文件到此区域</p>
                  <p>支持文件格式：.pdf</p>
                </div>
              </div>
            </Upload>
          </div>
        </div>
        <div class="authentication-sub-tit mt20">
          <span></span>
          <span>选填信息</span>
        </div>
        <div>
          <div class="authentication-cont mt20">
            <div class="authentication-cont-tit mb10">
              <span></span><span>上传工作照</span>
            </div>
            <div class="authentication-cont-list">
              <Upload type="drag" :action="constant.URL + '/uploadfile/upload'" :headers="{
                'access-token': Cookies.get(constant.tokenName)
              }" :data="{
  type: 0
}" accept=".jpg,.jpeg,.png" :format="['jpg', 'jpeg', 'png']" :on-success="avatarFileSuccess" :show-upload-list="false"
                :on-format-error="formatError" :on-exceeded-size="formatError" :max-size="5120" multiple>
                <div class="avatar-img" v-if="avatar">
                  <img :src="avatar" width="70" height="70" />
                  <div class="avatar-img-icon">
                    <Icon type="ios-eye-outline" @click.stop="handleAvatarView(avatar)"></Icon>
                    <Icon type="ios-trash-outline" @click.stop="handleAvatarRemove()"></Icon>
                  </div>
                </div>

                <div v-else>
                  <Icon type="ios-add" :size="70" />
                </div>
              </Upload>
            </div>
          </div>
          <div class="authentication-cont">
            <div class="authentication-cont-tit mb10">
              <span></span><span>个人简介</span>
            </div>
            <el-input type="textarea" :rows="2" placeholder="请输入内容" v-model="intro" style="width: 100%">
            </el-input>
          </div>
          <div class="authentication-cont">
            <div class="authentication-cont-tit">
              <span></span><span>语言能力（多选）</span>
            </div>
            <div class="authentication-cont-list">
              <p :class="item.status ? 'active' : ''" v-for="(item, index) in language_abilities" :key="item.id"
                @click="changeItem(index, language_abilities)">{{ item.name }}</p>
              <Input v-if="language_abilities.length && language_abilities[language_abilities.length - 1].status"
                v-model="languageAbilitiesDes" placeholder="请输入内容" style="width: 300px;margin-bottom: 10px" />
            </div>
          </div>
          <div class="authentication-cont">
            <div class="authentication-cont-tit">
              <span></span><span>时间安排（多选）</span>
            </div>
            <div class="authentication-cont-list">
              <p :class="item.status ? 'active' : ''" v-for="(item, index) in time_manage" :key="item.id"
                @click="changeItem(index, time_manage)">{{ item.name }}</p>
              <Input v-if="time_manage.length && time_manage[time_manage.length - 1].status" v-model="timeManageDes"
                placeholder="请输入内容" style="width: 300px;margin-bottom: 10px" />
            </div>
          </div>
        </div>
        <div>
          <div class="authentication-cont">
            <div class="authentication-cont-tit mb10">
              <span></span><span>教育经历</span>
            </div>
            <div class="exp-list">
              <div class="exp-list-item" v-for="(item, index) in education_exp" :key="item.id"
                @click="editEdu(item, index)">
                <img src="../../../assets/images/new_icon/guanbi.png" width="12" height="12" class="exp-list-item-close"
                  @click.stop="deleteEdu(index)" />
                <div class="exp-list-item-tit">{{ item.dateStr }}</div>
                <div class="exp-list-item-info">{{ item.schoolName }}({{ item.major }})</div>
              </div>
            </div>
            <el-button type="primary" @click="addEdu">+ 添加教育经历</el-button>
          </div>
        </div>
        <div>
          <div class="authentication-cont">
            <div class="authentication-cont-tit">
              <span></span><span>请上传之前做过的课程</span>
            </div>
            <div style="padding-left: 8px">
              <div class="mb10 mt10">文件上传</div>
              <div class="authentication-cont-list">
                <Upload type="drag" :action="constant.URL + '/uploadfile/upload'" :headers="{
                  'access-token': Cookies.get(constant.tokenName)
                }" :data="{
  type: 0
}" :on-success="courseFileSuccess" :show-upload-list="false" :on-format-error="formatError"
                  :on-exceeded-size="formatError" :max-size="5120" multiple>
                  <div>
                    <Icon type="ios-add" :size="70" />
                  </div>
                </Upload>
                <div class="course-file" v-if="make_courses.uploadUrl">
                  <p>已上传</p>
                  <p>{{ make_courses.uploadUrl }}</p>
                  <img src="../../../assets/images/new_icon/guanbi.png" width="12" height="12" class="course-close"
                    @click="deleteCourses" />
                </div>
              </div>

              <div class="mb10 mt10">可输入课程文件链接</div>
              <el-input v-model="make_courses.url" placeholder="请输入课程文件链接" style="width: 70%" />
            </div>

          </div>
        </div>
      </div>
    </el-drawer>
    <!-- 技术方向 -->
    <el-drawer :visible.sync="directionModal" :size="800">
      <div class="drawer-title" slot="title">
        <div class=drawer-title-text>请选择您擅长的技术方向（多选）</div>
        <div class="drawer-title-btns">
          <Button type="primary" @click="directionModal = false">确定</Button>
        </div>
      </div>
      <div style="padding: 20px;">
        <div class="search" v-if="directionList.length">
          <div style="position: relative">
            <div class="search-item">
              <div class="name">
                {{ $t('trainingcamp_exam_create_category_first') }}:
              </div>
              <div class="cont">
                <div class="values">
                  <div :class="firstDirectionIndex == index ? 'active' : ''" v-for="(item, index) in directionList"
                    :key="item.id + 'category'" @click="changeFirstDirection(index)">{{ item.name }}</div>
                </div>
              </div>
            </div>
          </div>
          <div style="position: relative">
            <div class="search-item"
              v-if="directionList[firstDirectionIndex].children && directionList[firstDirectionIndex].children.length">
              <div class="name">
                {{ $t('trainingcamp_exam_create_category_second') }}:
              </div>
              <div class="cont">
                <div class="values">
                  <div :class="secondDirectionIndex == index ? 'active' : ''"
                    v-for="(item, index) in directionList[firstDirectionIndex].children" :key="item.id + 'secondCategory'"
                    @click="changeSecondDirection(index)">{{ item.name }}</div>
                </div>
              </div>
            </div>
          </div>
          <div style="position: relative">
            <div class="search-item"
              v-if="directionList[firstDirectionIndex].children[secondDirectionIndex] && directionList[firstDirectionIndex].children[secondDirectionIndex].children.length">
              <div class="name">
                三级分类:
              </div>
              <div class="cont">
                <div class="values">
                  <div :class="item.directionStatus ? 'active' : ''"
                    v-for="item in directionList[firstDirectionIndex].children[secondDirectionIndex].children"
                    :key="item.id + 'thirdCategory'" @click="changeThirdDirection(item)">{{ item.name }}</div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="directionList[firstDirectionIndex].id == 99" class="mb20" style="margin-left: 20px">
            <el-input v-model="techDirectionDes" placeholder="请输入内容" :maxlength="20" style="width: 70%"
              @blur="saveDirectionDes" />
          </div>
          <div class="select-data">
            <p class="select-data-tit">已选择</p>
            <div class="select-data-cont">
              <div class="authentication-cont-list-tag" v-for="(item, index) in selectDirectionData" :key="index">
                <span v-if="item.firstId != 99">{{ item.firstName }}-{{ item.secondName }}-{{ item.name }}</span>
                <span v-else>{{ item.firstName }}-{{ item.desc }}</span>
                <Icon type="md-close" @click="deleteDirectionTag(index)" />
              </div>
              <div v-if="!selectDirectionData.length">暂无数据</div>
            </div>
          </div>
        </div>
      </div>
    </el-drawer>

    <!-- 所获资质证书 -->
    <el-drawer :visible.sync="certificationModal" :size="800">
      <div class="drawer-title" slot="title">
        <div class=drawer-title-text>请选择您已获得的技术认证（需相关证书支持）</div>
        <div class="drawer-title-btns">
          <Button type="primary" @click="certificationModal = false">确定</Button>
        </div>
      </div>
      <div style="padding: 20px;">
        <div class="search">
          <div style="position: relative">
            <div class="search-item">
              <div class="name">
                {{ $t('trainingcamp_exam_create_category_first') }}:
              </div>
              <div class="cont">
                <div class="values">
                  <div :class="curCategoryIndex == index ? 'active' : ''" v-for="(item, index) in categoryList"
                    :key="item.id + 'category'" @click="changeFirstCategory(index)">{{ item.name }}</div>
                </div>
              </div>
            </div>
          </div>
          <div style="position: relative">
            <div class="search-item" v-if="secondCategoryList.length">
              <div class="name">
                {{ $t('trainingcamp_exam_create_category_second') }}:
              </div>
              <div class="cont">
                <div class="values">
                  <div :class="cursecondCategoryIndex == index ? 'active' : ''"
                    v-for="(item, index) in secondCategoryList" :key="item.id + 'secondCategory'"
                    @click="changeSecondSearch(index)">{{ item.name }}</div>
                </div>
              </div>
            </div>
          </div>
          <div style="position: relative">
            <div class="search-item" v-if="thirdCategoryList.length">
              <div class="name">
                三级分类:
              </div>
              <div class="cont">
                <div class="values">
                  <div :class="item.status ? 'active' : ''" v-for="item in thirdCategoryList"
                    :key="item.id + 'thirdCategory'" @click="changeThirdCategory(item)">{{ item.name }}</div>
                </div>
              </div>
            </div>
          </div>
          <div class="select-data">
            <p class="select-data-tit">已选择</p>
            <div class="select-data-cont">
              <div class="authentication-cont-list-tag" v-for="(item, index) in selectCategoryData" :key="index">
                <span>{{ item.categoryName }}-{{ item.secondCategoryName }}-{{ item.name }}</span>
                <Icon type="md-close" @click="deleteTag(index)" />
              </div>
              <div v-if="!selectCategoryData.length">暂无数据</div>
            </div>
          </div>
        </div>
      </div>
    </el-drawer>
    <!-- 二维码 -->
    <el-drawer :visible.sync="followModal" @close="clearTimer" :size="800">
      <div class="drawer-title" slot="title">
        <div class=drawer-title-text>关注公众号</div>
        <div class="drawer-title-btns">
        </div>
      </div>
      <div style="padding: 20px;">
        <div style="text-align: center">
          <img :src="teacherWxCode" width="200" height="200" />
        </div>
      </div>
    </el-drawer>

    <!-- 工作经历 -->
    <el-drawer :visible.sync="workModal" :size="800">
      <div class="drawer-title" slot="title">
        <div class=drawer-title-text>请填写你的工作经历</div>
        <div class="drawer-title-btns">
          <el-button size="small" @click="clearWork">取消</el-button>
          <el-button size="small" type="primary" @click="confirmWork">确定</el-button>
        </div>
      </div>
      <div style="padding: 20px;">
        <el-form :model="workRuleForm" :rules="workRules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
          <el-form-item label="时间段" prop="date">
            <el-date-picker v-model="workRuleForm.date" type="monthrange" format="yyyy-MM" value-format="timestamp"
              start-placeholder="开始日期" end-placeholder="结束日期" :unlink-panels="true">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="公司名称" prop="companyName">
            <el-input v-model="workRuleForm.companyName"></el-input>
          </el-form-item>
          <el-form-item label="职位名称" prop="position">
            <el-input v-model="workRuleForm.position"></el-input>
          </el-form-item>
        </el-form>
      </div>
    </el-drawer>

    <!-- 培训/授课经历 -->
    <el-drawer :visible.sync="projectModal" :size="800">
      <div class="drawer-title" slot="title">
        <div class=drawer-title-text>请填写你的培训/授课经历</div>
        <div class="drawer-title-btns">
          <el-button size="small" @click="clearProject">取消</el-button>
          <el-button size="small" type="primary" @click="confirmProject">确定</el-button>
        </div>
      </div>
      <div style="padding: 20px;">
        <el-form :model="projectRuleForm" :rules="projectRules" ref="projectRuleForm" label-width="100px"
          class="demo-ruleForm">
          <el-form-item label="时间段" prop="date">
            <el-date-picker v-model="projectRuleForm.date" type="monthrange" format="yyyy-MM" value-format="timestamp"
              start-placeholder="开始日期" end-placeholder="结束日期" :unlink-panels="true" style="width: 340px">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="内容" prop="cont">
            <el-input v-model="projectRuleForm.cont" style="width: 340px"></el-input>
          </el-form-item>
        </el-form>
      </div>
    </el-drawer>

    <!-- 教育经历 -->
    <el-drawer :visible.sync="eduModal" :size="800">
      <div class="drawer-title" slot="title">
        <div class=drawer-title-text>请填写你的教育经历</div>
        <div class="drawer-title-btns">
          <el-button size="small" @click="clearEdu">取消</el-button>
          <el-button size="small" type="primary" @click="confirmEdu">确定</el-button>
        </div>
      </div>
      <div style="padding: 20px;">
        <el-form :model="eduRuleForm" :rules="eduRules" ref="eduRuleForm" label-width="100px" class="demo-ruleForm">
          <el-form-item label="时间段" prop="date">
            <el-date-picker v-model="eduRuleForm.date" type="monthrange" format="yyyy-MM" value-format="timestamp"
              start-placeholder="开始日期" end-placeholder="结束日期" :unlink-panels="true">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="学校名称" prop="schoolName">
            <el-input v-model="eduRuleForm.schoolName"></el-input>
          </el-form-item>
          <el-form-item label="职位名称" prop="major">
            <el-input v-model="eduRuleForm.major"></el-input>
          </el-form-item>
        </el-form>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import Cookies from "js-cookie";
import util from '@/utils/tools.js';
// import EditUser from '@/components/editUser.vue';
export default {
  name: "TeacherAuth",
  props: {
    show: {
      type: Boolean,
      default: false
    },
    isInfo: {
      type: Boolean,
      default: false,
    },
    isSuper: {
      type: Boolean,
      default: false,
    }
  },
  // components: {
  //   EditUser
  // },
  data() {
    let validateCompanyName = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入公司名称'));
      } else if (!util.removeSpaces(value)) {
        callback(new Error('请输入公司名称'));
      } else {
        callback();
      }
    };
    let validatePosition = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入职位名称'));
      } else if (!util.removeSpaces(value)) {
        callback(new Error('请输入职位名称'));
      } else {
        callback();
      }
    };
    let validateSchoolName = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入学校名称'));
      } else if (!util.removeSpaces(value)) {
        callback(new Error('请输入学校名称'));
      } else {
        callback();
      }
    };
    let validateMajor = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入专业名称'));
      } else if (!util.removeSpaces(value)) {
        callback(new Error('请输入专业名称'));
      } else {
        callback();
      }
    };
    let validateCont = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入内容'));
      } else if (!util.removeSpaces(value)) {
        callback(new Error('请输入内容'));
      } else {
        callback();
      }
    };

    return {
      Cookies: Cookies,
      userId: '',
      userLoading: false,
      userList: [],
      list: ['Alabama', 'Alaska', 'Arizona', 'Arkansas', 'California', 'Colorado', 'Connecticut', 'Delaware', 'Florida', 'Georgia', 'Hawaii', 'Idaho', 'Illinois', 'Indiana', 'Iowa', 'Kansas', 'Kentucky', 'Louisiana', 'Maine', 'Maryland', 'Massachusetts', 'Michigan', 'Minnesota', 'Mississippi', 'Missouri', 'Montana', 'Nebraska', 'Nevada', 'New hampshire', 'New jersey', 'New mexico', 'New york', 'North carolina', 'North dakota', 'Ohio', 'Oklahoma', 'Oregon', 'Pennsylvania', 'Rhode island', 'South carolina', 'South dakota', 'Tennessee', 'Texas', 'Utah', 'Vermont', 'Virginia', 'Washington', 'West virginia', 'Wisconsin', 'Wyoming'],
      // alipayAccount:'',
      email: '',
      uploadfileId: '',
      fileName: '',
      // techDirection:[],
      techSystem: [],
      workExp: [],
      techDirectionDes: '',
      techSystemDes: '',
      workExpDes: '',
      certificationModal: false,
      categoryList: [],  //一级分类
      curCategoryIndex: 0, //当前分类
      secondCategoryList: [],  //二级分类
      cursecondCategoryIndex: 0, //当前二级分类
      thirdCategoryList: [],  //三级级分类
      selectCategoryData: [],
      noCategoryData: [],
      certificateList: [],
      visible: false,
      certificateUrl: '',
      resumeUrl: '', //个人简历地址
      userName: '',
      dataId: '',
      remark: '',
      joinProject: '',
      timer: null,
      status: '',
      statusObj: {},
      refuseReason: '',
      saveModal: false,
      editUserModal: false,
      followModal: false,
      teacherWxCode: '',
      timer1: null,
      scene: '',
      userDetail: {},
      optionProps: {
        value: "id",
        label: "name",
        children: "children",
        checkStrictly: true
      },
      cityList: [],
      cityId: [],
      workRuleForm: {
        companyName: '',
        position: '',
        date: [],
        startDate: '',
        endDate: '',
      },
      workRules: {
        companyName: [
          { required: true, validator: validateCompanyName, message: '请输入公司名称', trigger: 'blur' },
        ],
        position: [
          { required: true, validator: validatePosition, message: '请输入职位名称', trigger: 'blur' }
        ],
        date: [
          { required: true, message: '请选择时间段', trigger: 'change' }
        ],
      },
      workModal: false,
      projectRuleForm: {
        cont: '',
        date: [],
        startDate: '',
        endDate: '',
      },
      projectRules: {
        cont: [
          { required: true, validator: validateCont, message: '请输入内容', trigger: 'blur' }
        ],
        date: [
          { required: true, message: '请选择时间段', trigger: 'change' }
        ],
      },
      projectModal: false,
      eduRuleForm: {
        schoolName: '',
        major: '',
        date: [],
        startDate: '',
        endDate: '',
      },
      eduRules: {
        schoolName: [
          { required: true, validator: validateSchoolName, message: '请输入学校名称', trigger: 'blur' },
        ],
        major: [
          { required: true, validator: validateMajor, message: '请输入专业名称', trigger: 'blur' }
        ],
        date: [
          { required: true, message: '请选择时间段', trigger: 'change' }
        ],
      },
      eduModal: false,
      textarea: '',
      directionModal: false,
      directionList: [],
      selectDirectionData: [],
      firstDirectionIndex: 0,
      secondDirectionIndex: 0,
      weixin: '',
      training_exp: [],
      job_exp: [],   //工作经历
      language_abilities: '',
      time_manage: '',
      education_exp: [],
      make_courses: {
        url: '',
        uploadUrl: '',
      },
      main_course: '',
      avatar: '',
      degree: '',
      workIndex: '',
      projectIndex: '',
      languageAbilitiesDes: '',
      timeManageDes: '',
      weixinSubscribe: '0',
      // 师资认证抽屉可见性
      drawerShow: false,
      intro:'', //个人简介
    }
  },
  created() {

    this.userName = this.$route.query.name || '';

    if (this.userName) {
      this.getUserList(this.$route.query.id, () => {
        this.userId = this.$route.query.id || '';
      });

      this.getDetail(this.$route.query.id);
    } else {
      if (!this.isInfo) {
        this.getTeacherForm();
      }
    }
    if (!this.isSuper && this.isInfo) {
      this.userId = this.$route.query.id || this.$route.params.id || this.$store.state.user.userInfo.id || '';
    } else {
      this.userId = this.$route.query.id || this.$route.params.id || '';
    }
    console.log(this.userId, 'this.userIdthis.userIdthis.userId')
    this.getUserDetail();
    this.getImage();
    this.getPlaceList();
  },
  watch: {
    '$store.state.user.userInfo.id': {

      handler: function (newV, oldV) {
        if (newV && this.isInfo && !this.isSuper) {
          this.userName = this.$store.state.user.userInfo.nickname;
          this.userId = this.$store.state.user.userInfo.id;
          this.getDetail(this.userId);
        }
        if (this.isSuper && newV) {
          this.userId = this.$route.params.id;
          this.getDetail(this.userId);
        }
      },
      immediate: true,
    },
    show(newVal) {
      this.drawerShow = newVal
    }
  },
  methods: {
    // 去认证
    goAuthentication() {
      this.drawerShow = true;
    },
    getPlaceList() { //获取地域
      this.api.site.placeList().then((res) => {
        this.cityList = res.list;
      })
    },
    editUserSuccess(data) {
      this.editUserModal = false;
      this.getUserList(data, () => {
        this.userId = '';
        this.$nextTick(() => {
          this.userId = this.$route.query.id || '';
        })

      });
    },
    closeEditUser() {
      this.editUserModal = false;
    },
    showSaveModal() {
      if (this.isInfo) {
        this.saveModal = true;
      } else {
        this.$emit("close");
        this.save();
      }
      // this.save();
    },
    closeSave() {
      this.saveModal = false;
    },
    confirmSave() {
      this.saveModal = false;
      this.save();
    },
    getDetail(userId) {
      let params = {
        user_id: userId
      };
      this.api.user.userTeacherDetail(params).then((res) => {
        if (res.info) {
          this.dataId = res?.info?.id || "";
          // 审核状态
          this.statusObj = res.statuses;
          this.status = res?.info?.status || "";
          // this.alipayAccount = res.info.ali_account;
          this.email = res?.info?.email || "";
          res?.info?.auth_category_names?.forEach((item) => {
            let data = [];
            let obj = {};
            for (let name in item) {
              data.push({
                id: name,
                name: item[name]
              })
            }
            if (data[0].id == 0) {
              obj = {
                categoryId: 0,
                categoryName: '',
                secondCategoryId: 0,
                secondCategoryName: '',
                id: 0,
                name: '',
              }
              this.noCategoryData.push(obj)
            } else {
              obj = {
                categoryId: data[0].id,
                categoryName: data[0].name,
                secondCategoryId: data[1] ? data[1].id : '',
                secondCategoryName: data[1] ? data[1].name : '',
                id: data[2] ? data[2].id : '',
                name: data[2] ? data[2].name : '',
              }
              this.selectCategoryData.push(obj)
            }



          })

          res?.info?.tech_direction_arr?.forEach((item) => {
            let data = [];
            let obj = {};
            for (let name in item) {
              if (name == 99) {
                data.push({
                  id: 99,
                  name: '其他',
                  desc: item[name]
                })
                break;
              } else {
                data.push({
                  id: name,
                  name: item[name]
                })
              }
            }
            if (data[0].id == 99) {
              obj = {
                firstId: data[0].id,
                firstName: data[0].name,
                desc: data[0].desc
              }
            } else {
              obj = {
                firstId: data[0].id,
                firstName: data[0].name,
                secondId: data[1] ? data[1].id : '',
                secondName: data[1] ? data[1].name : '',
                id: data[2] ? data[2].id : '',
                name: data[2] ? data[2].name : '',
              }
            }
            this.selectDirectionData.push(obj)
          })
          this.resumeUrl = res?.info?.resume || "";
          let urlArr = res?.info?.resume?.split('/') || [];
          this.fileName = urlArr[urlArr.length - 1] || "";

          this.certificateList = res.info.auth_cert ? res.info.auth_cert.split(',').map((item) => {
            return { url: item };
          }) : [];

          this.joinProject = res.info.join_project;
          this.remark = res.info.remarks;
          this.refuseReason = res.info.refuse_reason;

          this.weixin = res.info.weixin;
          this.cityId = [res.info.province_id, res.info.city_id];
          this.education_exp = res.info.education_exp_arr || [];
          this.training_exp = res.info.training_exp_arr || [];
          this.job_exp = res.info.job_exp_arr || [];
          this.main_course = res.info.main_course;
          this.avatar = res.info.avatar;
          this.make_courses.url = res.info.make_course && res.info.make_course.url;
          this.make_courses.uploadUrl = res.info.make_course && res.info.make_course.uploadUrl;
          this.weixinSubscribe = res.info.weixin_subscribe;
          this.intro = res.info.intro;
        }

        this.getTeacherForm(res.info);
      })
    },
    changeItem(index, data) {
      data[index].status = !data[index].status;
    },
    changeRadioItem(index, data) {
      data.forEach((item) => {
        item.status = false;
      })
      data[index].status = true;
    },
    fileSuccess(response, file, fileList) {  //上传文件成功
      // console.log(response.data.info,'dddd')
      this.uploadfileId = response.data.info.id;
      this.fileName = response.data.info.file_name;
      this.resumeUrl = response.data.show_url;
      this.$Message.success('上传文件成功');
    },
    certificateFileSuccess(response) {//上传证书成功
      this.certificateList.push({
        id: response.data.info.id,
        name: response.data.info.file_name,
        url: response.data.show_url
      })
    },
    formatError() {
      this.$Message.warning('请上传正确格式文件');
    },
    handleView(data) {
      this.visible = true;
      this.certificateUrl = data;
    },
    handleRemove(data) {
      let index = this.certificateList.findIndex((item) => {
        return item.id == data.id;
      });
      if (index != -1) {
        this.$delete(this.certificateList, index);
      }
    },
    handleAvatarView(data) {
      this.visible = true;
      this.certificateUrl = data;
    },
    handleAvatarRemove(data) {
      this.avatar = '';
    },

    getTeacherForm(data) {
      this.api.user.userTeacherForm().then((res) => {
        console.log("擅长技术体系数据", res);
        // this.techDirection = [];
        // for(let name in res.tech_direction){
        //   let status = false;
        //   if(data && data.tech_direction_arr){
        //     for(let sName in data.tech_direction_arr){
        //       if(sName == 99){
        //         this.techDirectionDes = data.tech_direction_arr[sName];
        //       }
        //       if(sName == name){
        //         status = true;
        //       }
        //     }
        //   }
        //   this.techDirection.push({
        //     id:name,
        //     name:res.tech_direction[name],
        //     status:status
        //   })
        // }
        this.directionList = res.tech_direction_show;


        this.techSystem = [];
        for (let name in res.tech_system) {
          let status = false;
          if (data && data.tech_system_arr) {
            for (let sName in data.tech_system_arr) {
              if (sName == 99) {
                this.techSystemDes = data.tech_system_arr[sName];
              }
              if (sName == name) {
                status = true;
              }
            }
          }
          this.techSystem.push({
            id: name,
            name: res.tech_system[name],
            status: status
          })
        }
        this.workExp = [];
        for (let name in res.work_exp) {
          let status = false;
          if (data && data.work_exp_arr) {
            for (let sName in data.work_exp_arr) {
              if (sName == 99) {
                this.workExpDes = data.work_exp_arr[sName];
              }
              if (sName == name) {
                status = true;
              }
            }
          }
          this.workExp.push({
            id: name,
            name: res.work_exp[name],
            status: status
          })
        }
        this.degree = [];
        for (let name in res.degrees) {
          let status = false;
          if (data && data.degree) {
            for (let sName in data.degree) {
              if (sName == name) {
                status = true;
              }
            }
          }
          this.degree.push({
            id: name,
            name: res.degrees[name],
            status: status
          })
        }


        this.language_abilities = [];
        for (let name in res.language_abilities) {
          let status = false;
          if (data && data.language_ability_arr) {
            for (let sName in data.language_ability_arr) {
              if (sName == 99) {
                this.languageAbilitiesDes = data.language_ability_arr[sName];
              }
              if (sName == name) {
                status = true;
              }
            }
          }
          this.language_abilities.push({
            id: name,
            name: res.language_abilities[name],
            status: status
          })
        }


        this.time_manage = [];
        for (let name in res.time_manages) {
          let status = false;
          if (data && data.time_manage_arr) {
            for (let sName in data.time_manage_arr) {
              if (sName == 99) {
                this.timeManageDes = data.time_manage_arr[sName];
              }
              if (sName == name) {
                status = true;
              }
            }
          }
          this.time_manage.push({
            id: name,
            name: res.time_manages[name],
            status: status
          })
        }
        this.degree = [];
        for (let name in res.degrees) {
          let status = false;
          if (data && data.degree) {
            if (data.degree == name) {
              status = true;
            }
          }
          this.degree.push({
            id: name,
            name: res.degrees[name],
            status: status
          })
        }
      })
    },

    addCategory() {
      this.certificationModal = true;
      this.getCategoryList();
    },
    noCategory() {
      this.noCategoryData = [
        {
          categoryId: '0',
          secondCategoryId: '0',
          id: '0'
        }
      ];
      this.selectCategoryData = [];
    },
    getCategoryList() {
      let data = {
        keyword: this.keyword,
        type: 0,
      };
      this.loading = true;
      this.api.dataset.categoryForm(data).then((res) => {
        this.loading = false;
        //一级标签
        this.categoryList = [
          ...res.categoryFirstList
        ];
        this.curCategoryId = this.categoryList[0].id;
        this.getSecondList();

      }).catch((err) => {
        this.loading = false;
      })
    },
    changeFirstCategory(index) {
      this.curCategoryIndex = index;
      this.getSecondList();
    },
    getSecondList() {
      let data = {
        keyword: this.keyword,
        category_id: this.categoryList[this.curCategoryIndex].id,
        type: 0,
        // second_category_id:this.cursecondCategoryId
      };
      this.api.dataset.categoryForm(data).then((res) => {
        //一级标签
        this.secondCategoryList = [
          ...res.categorySecondList
        ];
        this.cursecondCategoryIndex = 0;
        this.getThreeList();
      })
    },
    changeSecondSearch(index) {
      this.cursecondCategoryIndex = index;
      this.getThreeList();
    },
    getThreeList() {
      let data = {
        keyword: this.keyword,
        category_id: this.categoryList[this.curCategoryIndex].id,
        category_second_id: this.secondCategoryList[this.cursecondCategoryIndex].id,
        type: 0,
      };
      this.api.dataset.categoryForm(data).then((res) => {
        //三级标签
        this.thirdCategoryList = [
          ...res.categoryThirdList
        ];
        this.thirdCategoryList.forEach((item) => {
          let index = this.selectCategoryData.findIndex((sItem) => {
            return item.id == sItem.id;
          });
          if (index != -1) {
            this.$set(item, 'status', true);
          } else {
            this.$set(item, 'status', false);
          }

        })
      })
    },
    changeThirdCategory(data) {
      data.status = !data.status;
      if (data.status) {
        let obj = {
          categoryId: this.categoryList[this.curCategoryIndex].id,
          categoryName: this.categoryList[this.curCategoryIndex].name,
          secondCategoryId: this.secondCategoryList[this.cursecondCategoryIndex].id,
          secondCategoryName: this.secondCategoryList[this.cursecondCategoryIndex].name,
          ...data
        }
        this.selectCategoryData.push(obj);
        if (this.noCategoryData.length) {
          this.noCategoryData = [];
        }

      } else {
        let index = this.selectCategoryData.findIndex((item) => {
          return item.id == data.id;
        });
        if (index != -1) {
          this.$delete(this.selectCategoryData, index);
        }
      }
    },
    deleteTag(index) {
      this.$delete(this.selectCategoryData, index);
      this.thirdCategoryList.forEach((item) => {
        let index = this.selectCategoryData.findIndex((sItem) => {
          return item.id == sItem.id;
        });
        if (index != -1) {
          this.$set(item, 'status', true);
        } else {
          this.$set(item, 'status', false);
        }

      })
    },
    getUserList(data, fn) {
      let params = {
        pageSize: 20,
        page: 1,
        keyword: data || '',
        is_filter_teacher: 1,
        site_id: -1,
        user_id: -1
      };

      this.userLoading = true;
      this.api.production.userList(params).then((res) => {
        this.userLoading = false;
        this.userList = res.list;
        // console.log(this.userList,'this.userList')
        // if(type == 'edit'){
        //   this.userId = this.$route.query.id || '';
        // }
        fn && fn();

      }).catch((err) => {
        this.userLoading = false;
      })
    },
    remoteMethod(query) {
      if (query !== '') {
        if (this.timer) return;
        this.timer = setTimeout(() => {

          this.getUserList(query);
          this.timer = null
        }, 500)
        // this.getUserList(query);
      } else {
        // console.log('111111')
        this.userList = [];
      }
    },
    cancel() {
      this.$emit("close");
    },
    save() {
      if (!this.userId) {
        this.$Message.warning('请选择用户');
        return;
      }
      let directionIds = '';
      if (this.selectDirectionData.length) {
        directionIds = this.selectDirectionData.map((item) => {
          let str = '';
          if (item.firstId != 99) {
            str = item.firstId + '|' + item.secondId + '|' + item.id;
          } else {
            str = item.firstId + '|' + item.desc;
          }
          return str;
        })
      } else {
        this.$Message.warning('请选择技术方向');
        return;
      }


      let techSystemData = this.techSystem.filter((item) => {
        return item.status;
      });
      if (!techSystemData.length) {
        this.$Message.warning('请选择技术体系');
        return;
      }
      let techSystemLen = techSystemData.filter((item) => {
        return item.id == 99
      }).length;
      techSystemData = techSystemData.map((item) => {
        return item.id
      });

      if (techSystemLen) {
        let techSystemDes = util.removeSpaces(this.techSystemDes);
        if (!techSystemDes) {
          this.$Message.warning('请输入内容');
          return;
        }
        techSystemData.push(
          encodeURIComponent(techSystemDes)
        )
      }

      // let workExpData = this.workExp.filter((item)=>{
      //   return item.status;
      // }).map((item)=>{
      //   return item.id
      // });
      // if(!workExpData.length){
      //   this.$Message.warning('请选择行业经验');
      //   return;
      // }

      let workExpData = this.workExp.filter((item) => {
        return item.status;
      });
      if (!workExpData.length) {
        this.$Message.warning('请选择行业经验');
        return;
      }
      let workExpDataLen = workExpData.filter((item) => {
        return item.id == 99
      }).length;
      workExpData = workExpData.map((item) => {
        return item.id
      });

      if (workExpDataLen) {
        let workExpDes = util.removeSpaces(this.workExpDes);
        if (!workExpDes) {
          this.$Message.warning('请输入内容');
          return;
        }
        workExpData.push(
          encodeURIComponent(workExpDes)
        )
      }
      console.log(this.cityId, 'dddddd')
      if (!this.cityId.length) {
        this.$Message.warning('请选择常驻地');
        return;
      }
      let weixin = util.removeSpaces(this.weixin);
      if (!weixin) {
        this.$Message.warning('请输入微信号');
        return;
      }


      let degreeData = this.degree.filter((item) => {
        return item.status;
      }).map((item) => {
        return item.id;
      });
      if (!degreeData.length) {
        this.$Message.warning('请选择最高学历');
        return;
      }

      if (!this.job_exp.length) {
        this.$Message.warning('请添加工作经历');
        return;
      }

      if (!this.training_exp.length) {
        this.$Message.warning('请添加培训/授课经历');
        return;
      }
      let main_course = util.removeSpaces(this.main_course);
      if (!main_course) {
        this.$Message.warning('请输入主讲课程');
        return;
      }
      let categoryIds = this.selectCategoryData.map((item) => {
        return item.categoryId + '|' + item.secondCategoryId + '|' + item.id;
      })
      if (!categoryIds.length && !this.noCategoryData.length) {
        this.$Message.warning('请添加资质');
        return;
      }
      let authCert = this.certificateList.map((item) => {
        return item.url;
      })
      if (!this.noCategoryData.length) {
        if (!authCert.length) {
          this.$Message.warning('请上传证书');
          return;
        }
      }



      if (!this.resumeUrl) {
        this.$Message.warning('请上传个人简历');
        return;
      }

      //语言能力
      let languageAbilitiesData = this.language_abilities.filter((item) => {
        return item.status;
      });
      if (languageAbilitiesData.length) {
        let languageAbilitiesDataLen = languageAbilitiesData.filter((item) => {
          return item.id == 99
        }).length;
        languageAbilitiesData = languageAbilitiesData.map((item) => {
          return item.id
        });

        if (languageAbilitiesDataLen) {
          let languageAbilitiesDes = util.removeSpaces(this.languageAbilitiesDes);
          if (!languageAbilitiesDes) {
            this.$Message.warning('请输入内容');
            return;
          }
          languageAbilitiesData.push(
            encodeURIComponent(languageAbilitiesDes)
          )
        }
      }
      //时间管理
      let timeManageData = this.time_manage.filter((item) => {
        return item.status;
      });
      if (timeManageData.length) {
        let timeManageDataLen = timeManageData.filter((item) => {
          return item.id == 99
        }).length;
        timeManageData = timeManageData.map((item) => {
          return item.id
        });

        if (timeManageDataLen) {
          let timeManageDes = util.removeSpaces(this.timeManageDes);
          if (!timeManageDes) {
            this.$Message.warning('请输入内容');
            return;
          }
          timeManageData.push(
            encodeURIComponent(timeManageDes)
          )
        }
      }



      let params = {
        user_id: this.userId,
        // ali_account:this.alipayAccount,
        // email:this.email,
        tech_direction: directionIds.join(','),
        tech_system: techSystemData.join(','),
        work_exp: workExpData.join(','),
        resume: this.resumeUrl,
        auth_category_ids: categoryIds.join(',') || '0|0|0',
        auth_cert: authCert.join(','),
        remarks: this.remark,
        join_project: this.joinProject,
        province_id: this.cityId[0],
        city_id: this.cityId[1] || '',
        weixin: weixin,
        training_exp: this.training_exp,
        job_exp: this.job_exp,
        language_abilities: languageAbilitiesData.join(','),
        time_manage: timeManageData.join(','),
        education_exp: this.education_exp,
        make_courses: this.make_courses,
        main_course: main_course,
        avatar: this.avatar,
        degree: degreeData.join(','),
        is_submit: '1',
        intro:this.intro,
      }
      if (!this.isInfo || this.isSuper) {
        params.site_id = -1;
      }
      if (this.dataId) {
        this.api.user.userTeacherUpdate(params).then((res) => {
          this.$Message.success('编辑成功');
          this.drawerShow = false;
          // if (!this.isInfo) {
          //   this.$router.go(-1);
          // }

        })
      } else {
        this.api.user.userTeacherCreate(params).then((res) => {
          this.$Message.success('操作成功');
          this.drawerShow = false;
        })
      }


    },
    stagingData() {  //暂存

      let directionIds = '';
      if (this.selectDirectionData.length) {
        directionIds = this.selectDirectionData.map((item) => {
          let str = '';
          if (item.firstId != 99) {
            str = item.firstId + '|' + item.secondId + '|' + item.id;
          } else {
            str = item.firstId + '|' + item.desc;
          }
          return str;
        })
      }


      let techSystemData = this.techSystem.filter((item) => {
        return item.status;
      });

      let techSystemLen = techSystemData.filter((item) => {
        return item.id == 99
      }).length;
      techSystemData = techSystemData.map((item) => {
        return item.id
      });

      if (techSystemLen) {
        if (this.techSystemDes) {
          techSystemData.push(
            encodeURIComponent(this.techSystemDes)
          )
        }

      }

      // let workExpData = this.workExp.filter((item)=>{
      //   return item.status;
      // }).map((item)=>{
      //   return item.id
      // });
      // if(!workExpData.length){
      //   this.$Message.warning('请选择行业经验');
      //   return;
      // }

      let workExpData = this.workExp.filter((item) => {
        return item.status;
      });

      let workExpDataLen = workExpData.filter((item) => {
        return item.id == 99
      }).length;
      workExpData = workExpData.map((item) => {
        return item.id
      });

      if (workExpDataLen) {
        if (this.workExpDes) {
          workExpData.push(
            encodeURIComponent(this.workExpDes)
          )
        }
      }


      let degreeData = this.degree.filter((item) => {
        return item.status;
      }).map((item) => {
        return item.id;
      });

      let categoryIds = this.selectCategoryData.map((item) => {
        return item.categoryId + '|' + item.secondCategoryId + '|' + item.id;
      })
      let authCert = this.certificateList.map((item) => {
        return item.url;
      })


      //语言能力
      let languageAbilitiesData = this.language_abilities.filter((item) => {
        return item.status;
      });
      if (languageAbilitiesData.length) {
        let languageAbilitiesDataLen = languageAbilitiesData.filter((item) => {
          return item.id == 99
        }).length;
        languageAbilitiesData = languageAbilitiesData.map((item) => {
          return item.id
        });

        if (languageAbilitiesDataLen) {
          if (this.languageAbilitiesDes) {
            languageAbilitiesData.push(
              encodeURIComponent(this.languageAbilitiesDes)
            )
          }
        }
      }
      //时间管理
      let timeManageData = this.time_manage.filter((item) => {
        return item.status;
      });
      if (timeManageData.length) {
        let timeManageDataLen = timeManageData.filter((item) => {
          return item.id == 99
        }).length;
        timeManageData = timeManageData.map((item) => {
          return item.id
        });

        if (timeManageDataLen) {
          if (this.timeManageDes) {
            timeManageData.push(
              encodeURIComponent(this.timeManageDes)
            )
          }

        }
      }

      let params = {
        user_id: this.userId,
        // ali_account:this.alipayAccount,
        // email:this.email,
        tech_direction: directionIds.join(','),
        tech_system: techSystemData.join(','),
        work_exp: workExpData.join(','),
        resume: this.resumeUrl,
        auth_category_ids: categoryIds.join(','),
        auth_cert: authCert.join(','),
        remarks: this.remark,
        join_project: this.joinProject,
        province_id: this.cityId[0],
        city_id: this.cityId[1] || '',
        weixin: this.weixin,
        training_exp: this.training_exp,
        job_exp: this.job_exp,
        language_abilities: languageAbilitiesData.join(','),
        time_manage: timeManageData.join(','),
        education_exp: this.education_exp,
        make_courses: this.make_courses,
        main_course: this.main_course,
        avatar: this.avatar,
        degree: degreeData,
        is_submit: '0'
      }
      if (!this.isInfo || this.isSuper) {
        params.site_id = -1;
      }
      if (this.dataId) {
        this.api.user.userTeacherUpdate(params).then((res) => {

        })
      } else {
        this.api.user.userTeacherCreate(params).then((res) => {
          this.dataId = res.info.id;
        })
      }
    },
    editUser() {
      this.editUserModal = true;
    },
    checkResume(data) {
      window.open(data, '_blank')
    },
    goInfo(data) {
      this.current = data;
      if (data == 0 || data == 1) {
        this.status = false;
      }
      if (data == 2) {
        if (this.weixinSubscribe == '1') {
          this.$Message.warning('已绑定');
          return;
        }
        this.followModal = true;
        clearInterval(this.timer1);
        this.timer1 = setInterval(() => {
          this.getWxDetail();
        }, 1000)
      }

    },
    getWxDetail() {
      let params = {
        scene: this.scene
      };
      this.api.index.WeixinServerQrBinding(params).then((res) => {
        if (res.weixin_subscribe == '1') {
          this.weixinSubscribe = res.weixin_subscribe;
          clearInterval(this.timer1);
          this.followModal = false;
          this.$emit('editSuccess')
        } else {
          this.weixinSubscribe = '0';
        }
      }).catch((err) => {
        clearInterval(this.timer1);
        this.followModal = false;
        this.$emit('editSuccess')
      })
    },
    clearTimer() {
      console.log('2222')

      clearInterval(this.timer1);
      this.getWxDetail();
    },
    getImage() { //获取二维码图片
      this.api.index.WeixinServerQrShow().then((res) => {
        this.teacherWxCode = res.showqrcode;
        this.scene = res.scene;
      })
    },

    getUserDetail() {
      let params = {
        with_weixin_subscribe: 1
      };
      if (this.userId) {
        params.user_id = this.userId
      }

      this.api.user.userDetail(params).then((res) => {
        this.userDetail = res.user;
        this.weixinSubscribe = res.user.weixin_subscribe;
      })
    },
    confirmWork() {
      this.$refs['ruleForm'].validate((valid) => {
        if (valid) {
          let startTime = util.timeFormatter(new Date(+this.workRuleForm.date[0]), 'yyyy.MM');
          let endTime = util.timeFormatter(new Date(+this.workRuleForm.date[1]), 'yyyy.MM');
          console.log(this.workIndex, 'this.workIndexthis.workIndexthis.workIndex')
          if (this.workIndex || this.workIndex === 0) {
            this.job_exp[this.workIndex].companyName = util.removeSpaces(this.workRuleForm.companyName);
            this.job_exp[this.workIndex].position = util.removeSpaces(this.workRuleForm.position);
            this.job_exp[this.workIndex].startDate = this.workRuleForm.date[0];
            this.job_exp[this.workIndex].endDate = this.workRuleForm.date[1];
            this.job_exp[this.workIndex].dateStr = startTime + '-' + endTime;
          } else {
            this.job_exp.push({
              companyName: util.removeSpaces(this.workRuleForm.companyName),
              position: util.removeSpaces(this.workRuleForm.position),
              startDate: this.workRuleForm.date[0],
              endDate: this.workRuleForm.date[1],
              dateStr: startTime + '-' + endTime
            })
          }

          this.workModal = false;
        }
      });
    },
    clearWork() {
      this.workModal = false;
    },
    confirmProject() {
      this.$refs['projectRuleForm'].validate((valid) => {
        if (valid) {
          let startTime = util.timeFormatter(new Date(+this.projectRuleForm.date[0]), 'yyyy.MM');
          let endTime = util.timeFormatter(new Date(+this.projectRuleForm.date[1]), 'yyyy.MM');

          if (this.projectIndex || this.projectIndex === 0) {

            this.training_exp[this.projectIndex].cont = util.removeSpaces(this.projectRuleForm.cont);
            this.training_exp[this.projectIndex].startDate = this.projectRuleForm.date[0];
            this.training_exp[this.projectIndex].endDate = this.projectRuleForm.date[1];
            this.training_exp[this.projectIndex].dateStr = startTime + '-' + endTime;
          } else {
            this.training_exp.push({
              cont: util.removeSpaces(this.projectRuleForm.cont),
              startDate: this.projectRuleForm.date[0],
              endDate: this.projectRuleForm.date[1],
              dateStr: startTime + '-' + endTime
            })
          }
          this.projectModal = false;
        }
      });
    },
    clearProject() {
      this.projectModal = false;
    },
    confirmEdu() {
      this.$refs['eduRuleForm'].validate((valid) => {
        if (valid) {
          let startTime = util.timeFormatter(new Date(+this.eduRuleForm.date[0]), 'yyyy.MM');
          let endTime = util.timeFormatter(new Date(+this.eduRuleForm.date[1]), 'yyyy.MM');

          if (this.eduIndex || this.eduIndex === 0) {
            this.education_exp[this.workIndex].major = util.removeSpaces(this.eduRuleForm.major);
            this.education_exp[this.workIndex].schoolName = util.removeSpaces(this.eduRuleForm.schoolName);
            this.education_exp[this.workIndex].startDate = this.eduRuleForm.date[0];
            this.education_exp[this.workIndex].endDate = this.eduRuleForm.date[1];
            this.education_exp[this.workIndex].dateStr = startTime + '-' + endTime;
          } else {
            this.education_exp.push({
              major: util.removeSpaces(this.eduRuleForm.major),
              schoolName: util.removeSpaces(this.eduRuleForm.schoolName),
              startDate: this.eduRuleForm.date[0],
              endDate: this.eduRuleForm.date[1],
              dateStr: startTime + '-' + endTime
            })
          }
          this.eduModal = false;
        }
      });
    },
    clearEdu() {
      this.eduModal = false;
    },
    saveDirectionDes() {
      if (this.directionList[this.firstDirectionIndex].id == 99) {
        let index = this.selectDirectionData.findIndex((item) => {
          return item.firstId == 99
        });
        if (this.techDirectionDes) {
          if (index > -1) {
            this.selectDirectionData[index].firstName = this.directionList[this.firstDirectionIndex].name;
            this.selectDirectionData[index].firstId = this.directionList[this.firstDirectionIndex].id;
            this.selectDirectionData[index].desc = this.techDirectionDes;
          } else {
            this.selectDirectionData.push({
              firstName: this.directionList[this.firstDirectionIndex].name,
              firstId: this.directionList[this.firstDirectionIndex].id,
              desc: this.techDirectionDes,
            });
          }
        } else {
          this.$delete(this.selectDirectionData, index);
        }
      }
    },
    changeFirstDirection(index) {
      this.firstDirectionIndex = index;
      this.secondDirectionIndex = 0;
      if (this.directionList[this.firstDirectionIndex].children.length && this.directionList[this.firstDirectionIndex].children[this.secondDirectionIndex].children.length) {
        this.directionList[this.firstDirectionIndex].children[this.secondDirectionIndex].children.forEach((item) => {
          let index = this.selectDirectionData.findIndex((sItem) => {
            return item.id == sItem.id;
          });
          if (index != -1) {
            this.$set(item, 'directionStatus', true);
          } else {
            this.$set(item, 'directionStatus', false);
          }
        })
      } else if (this.directionList[this.firstDirectionIndex].id == 99) {
        let index = this.selectDirectionData.findIndex((sItem) => {
          return this.directionList[this.firstDirectionIndex].id == sItem.firstId;
        });
        if (index != -1) {
          this.techDirectionDes = this.selectDirectionData[index].desc;
        } else {
          this.techDirectionDes = '';
        }
      }
    },
    changeSecondDirection(index) {
      this.secondDirectionIndex = index;

      if (this.directionList[this.firstDirectionIndex].children[this.secondDirectionIndex].children.length) {
        this.directionList[this.firstDirectionIndex].children[this.secondDirectionIndex].children.forEach((item) => {
          let index = this.selectDirectionData.findIndex((sItem) => {
            return item.id == sItem.id;
          });
          if (index != -1) {
            this.$set(item, 'directionStatus', true);
          } else {
            this.$set(item, 'directionStatus', false);
          }
        })
      }
    },
    changeThirdDirection(data) {
      data.directionStatus = !data.directionStatus;
      if (data.directionStatus) {
        let obj = {
          firstId: this.directionList[this.firstDirectionIndex].id,
          firstName: this.directionList[this.firstDirectionIndex].name,
          secondId: this.directionList[this.firstDirectionIndex].children[this.secondDirectionIndex].id,
          secondName: this.directionList[this.firstDirectionIndex].children[this.secondDirectionIndex].name,
          ...data
        }
        this.selectDirectionData.push(obj);
      } else {
        let index = this.selectDirectionData.findIndex((item) => {
          return item.id == data.id;
        });
        if (index != -1) {
          this.$delete(this.selectDirectionData, index);
        }
      }
    },
    deleteDirectionTag(index) {
      this.$delete(this.selectDirectionData, index);
    },
    addDirection() {
      this.directionModal = true;
      this.techDirectionDes = '';

      if (this.directionList[this.firstDirectionIndex].children.length && this.directionList[this.firstDirectionIndex].children[this.secondDirectionIndex].children.length) {
        this.directionList[this.firstDirectionIndex].children[this.secondDirectionIndex].children.forEach((item) => {
          let index = this.selectDirectionData.findIndex((sItem) => {
            return item.id == sItem.id;
          });
          if (index != -1) {
            this.$set(item, 'directionStatus', true);
          } else {
            this.$set(item, 'directionStatus', false);
          }
        })
      } else if (this.directionList[this.firstDirectionIndex].id == 99) {
        let index = this.selectDirectionData.findIndex((sItem) => {
          return this.directionList[this.firstDirectionIndex].id == sItem.firstId;
        });
        console.log(index, 'index=========')
        if (index != -1) {
          this.techDirectionDes = this.selectDirectionData[index].desc;
        } else {
          this.techDirectionDes = '';
        }
      }
    },
    addWork() {
      this.$refs['ruleForm']?.resetFields();
      this.workModal = true;
      this.workRuleForm.companyName = '';
      this.workRuleForm.position = ''
      this.workRuleForm.date = [];
      this.workIndex = '';
    },
    editWork(data, index) {
      this.$refs['ruleForm']?.resetFields();
      this.workModal = true;
      this.workRuleForm.companyName = data.companyName;
      this.workRuleForm.position = data.position;
      this.workRuleForm.date = [data.startDate, data.endDate];
      this.workIndex = index;
    },
    deleteWork(index) {
      this.$delete(this.job_exp, index);
    },
    addProject() {
      this.$refs['projectRuleForm']?.resetFields();
      this.projectModal = true;
      this.projectRuleForm.date = [];
      this.projectRuleForm.cont = '';
      this.projectIndex = '';
    },
    editProject(data, index) {
      this.$refs['projectRuleForm']?.resetFields();
      this.projectModal = true;
      this.projectRuleForm.cont = data.cont;
      this.projectRuleForm.date = [data.startDate, data.endDate];
      this.projectIndex = index;
    },
    deleteProject(index) {
      this.$delete(this.training_exp, index);
    },
    avatarFileSuccess(response) {
      this.avatar = response.data.show_url;
    },
    addEdu() {
      this.$refs['eduRuleForm']?.resetFields();
      this.eduModal = true;
      this.eduRuleForm.date = [];
      this.eduRuleForm.cont = '';
      this.eduRuleForm.major = '';
      this.eduRuleForm.schoolName = '';
      this.eduIndex = '';
    },
    editEdu(data, index) {
      this.$refs['eduRuleForm']?.resetFields();
      this.eduModal = true;
      this.eduRuleForm.cont = data.cont;
      this.eduRuleForm.date = [data.startDate, data.endDate];
      this.eduRuleForm.major = data.major;
      this.eduRuleForm.schoolName = data.schoolName;
      this.eduIndex = index;
    },
    deleteEdu(index) {
      this.$delete(this.education_exp, index);
    },
    courseFileSuccess(response) {
      this.make_courses.uploadUrl = response.data.show_url;
    },
    deleteCourses() {
      this.make_courses.uploadUrl = '';
    }
  }
}
</script>

<style scoped lang="scss">
.noPadding {
  box-shadow: none !important;
  padding: 0 !important;
  margin: 0 !important;
  height: calc(100vh - 350px) !important;
}

.authentication {
  // margin: 20px;
  // padding: 20px 20px 80px 20px;
  background: #FFFFFF;
  // box-shadow: 0px 2px 12px 0px rgba(189, 189, 189, 0.4100);
  border-radius: 4px;
  font-size: 14px;
  // height: calc(100vh - 130px);
  //height: calc(100vh - 350px);
  overflow: hidden;
  position: relative;

  .wrap{
    height: 100%;
    overflow: auto;
    padding-bottom: 50px;
  }
  .fixed {
    position: absolute;
    bottom: 0px;
    width: 100%;
    background-color: white;
    padding: 20px 0;
  }

  .authentication-tit {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
  }

  .authentication-sub-tit {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    >span:nth-child(1) {
      margin-right: 8px;
      display: inline-block;
      width: 6px;
      height: 16px;
      background: linear-gradient(93deg, #4A79FF, #0042FF);
      border-radius: 4px
    }
  }

  .authentication-status {
    >p {
      font-size: 16px;
      font-weight: bold;
      margin-bottom: 5px;
    }

    .audit {
      color: #4A79FF;
    }

    .success {
      color: #19be6b;
    }

    .fail {
      color: #ed4014;
    }
  }

  .authentication-reason {
    >span {
      color: #ed4014;
    }
  }

  .flex-layout {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .authentication-cont {
    margin-bottom: 20px;
    padding: 20px;
    background: #F6F7FA !important;
    //border:1px solid #dcdee2;


    .authentication-cont-r {
      flex: 1;
      width: 0;
      //display: flex;
      //justify-content: flex-end;
      //align-items: center;
    }

    .authentication-cont-item {
      margin-bottom: 20px;
      display: flex;
      justify-content: flex-start;

      >p {
        margin-right: 10px;
        width: 100px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        font-size: 16px;
        font-weight: bold;

        >span:first-child {
          padding-top: 5px;
          margin-right: 5px;
          color: red;
          font-size: 20px;
        }
      }
    }

    .bor-bot {
      padding-bottom: 20px;
      border-bottom: 1px solid #cccccc;
    }

    .authentication-cont-item:last-child {
      margin-bottom: 0;
    }

    .authentication-cont-tit {
      font-size: 16px;
      font-weight: bold;
      display: flex;
      justify-content: flex-start;
      align-items: center;

      >span:first-child {
        padding-top: 5px;
        margin-right: 5px;
        color: red;
        font-size: 20px;
      }
    }

    .authentication-cont-list {
      margin-top: 10px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-wrap: wrap;

      >p {
        margin-right: 10px;
        margin-bottom: 10px;
        padding: 0 10px;
        height: 32px;
        line-height: 32px;
        border: 1px solid #dcdee2;
        border-radius: 4px;
        cursor: pointer;
      }

      >p.active {
        color: #4A79FF;
        border: 1px solid #4A79FF;
      }

      .upload-cont {
        margin: 0 auto;
        width: 100%;

        .upload-cont-info {
          margin: 0 auto;
          width: 340px;

          .upload-cont-info-message {
            margin: 20px 0;
            padding-left: 20px;
            text-align: left;
            line-height: 28px;
          }
        }
      }

      .authentication-cont-list-add {
        margin-right: 10px;
        margin-bottom: 10px;
        padding: 0 5px;
        //border: 1px solid #dcdee2;
        //border-radius: 4px;
        height: 32px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
      }

      .certificate-list {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;

        .certificate-list-item {
          margin-right: 10px;
          width: 70px;
          height: 70px;
          text-align: center;
          line-height: 70px;
          position: relative;

          >img {
            width: 100%;
            height: 100%;
          }

          .certificate-list-item-icon {
            display: none;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background: rgba(0, 0, 0, .6);

            >i {
              color: #fff;
              font-size: 20px;
              cursor: pointer;
              margin: 0 2px;
            }
          }
        }

        .certificate-list-item:hover .certificate-list-item-icon {
          display: block;
        }
      }
    }

    .exp-list {
      .exp-list-item {
        margin-bottom: 20px;
        background-color: #FFFFFF;
        padding: 20px;
        position: relative;

        .exp-list-item-tit {
          color: #999999;
          margin-bottom: 8px;
        }

        .exp-list-item-info {
          line-height: 26px;
        }

        .exp-list-item-close {
          position: absolute;
          right: 20px;
          top: 10px;
          cursor: pointer;
        }
      }
    }

    .course-file {
      margin-left: 20px;
      position: relative;
      width: 400px;

      .course-close {
        position: absolute;
        right: 0;
        top: 0;
      }
    }

  }
}

.authentication-cont-list-tag {
  margin-right: 10px;
  margin-bottom: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0 10px;
  height: 32px;
  line-height: 32px;
  border: 1px solid #dcdee2;
  border-radius: 4px;
  cursor: pointer;

  >span {
    margin-right: 10px;
  }
}

.search {
  margin-bottom: 20px;

  .search-item {
    margin-bottom: 15px;
    font-size: 14px;

    .name {
      margin-bottom: 5px;
      padding: 5px 0;
      width: 75px;
      text-align: right;
    }

    .cont {
      margin-left: 15px;
      flex: 1;
      display: flex;
      justify-content: space-between;

      .values {
        display: flex;
        justify-content: flex-start;
        flex-flow: wrap;
        transition: max-height 0.3s linear;
        overflow: hidden;

        >div {
          margin-right: 10px;
          margin-bottom: 10px;
          padding: 5px 10px;
          //margin: 0 10px 10px 5px;
          cursor: pointer;
          border: 1px solid #dcdee2;
          border-radius: 4px;
        }

        >div.active {
          background-color: #4A79FF;
          color: #FFFFFF;
          border-radius: 4px;
        }
      }

      .more {
        padding: 5px 0;
        width: 80px;
        text-align: right;
        cursor: pointer;

        .more-icon {
          transition: transform 0.3s linear;
        }
      }
    }
  }

  .select-data {
    padding: 20px;
    border: 1px solid #dcdee2;
    border-radius: 4px;

    .select-data-tit {
      font-weight: bold;
      font-size: 14px;
    }

    .select-data-cont {
      margin-top: 10px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-wrap: wrap;

    }
  }

}

.save-message {
  font-size: 14px;
  text-align: center;
  line-height: 30px;
}

.resume {
  display: flex;
  justify-content: center;
  align-items: center;

  .preview {
    margin-bottom: 20px;
    padding: 10px;
    color: #4A79FF;
    font-size: 14px;
  }
}

.avatar-img {
  width: 70px;
  height: 70px;
  position: relative;

  .avatar-img-icon {
    display: none;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, .6);


    >i {
      color: #fff;
      font-size: 20px;
      cursor: pointer;
      margin-top: 24px;
    }
  }
}

.avatar-img:hover {
  .avatar-img-icon {
    display: block;
  }
}
</style>
<!--<style>-->
<!--.el-cascader-menu {-->
<!--  height: 200px !important;-->
<!--}-->
<!--</style>-->
