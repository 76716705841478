<template>
<div class="teacher-information">
  <div class="teacher-information-item" v-if="info.intro">
    <div class="teacher-information-item-tit">
      <p class="teacher-information-item-tit-icon"></p>
      <p class="teacher-information-item-tit-name">个人简介</p>
    </div>
    <div class="teacher-information-item-cont">
      {{ info.intro }}
    </div>
  </div>
  <div class="teacher-information-item">
    <div class="teacher-information-item-tit">
      <p class="teacher-information-item-tit-icon"></p>
      <p class="teacher-information-item-tit-name">微信公众号</p>
      <p class="teacher-information-item-tit-tag">{{info.weixin_subscribe == '1' ? '已关注' : '去关注'}}</p>
    </div>
  </div>
  <div class="teacher-information-item">
    <div class="teacher-information-item-tit">
      <p class="teacher-information-item-tit-icon"></p>
      <p class="teacher-information-item-tit-name">技术方向</p>
    </div>
    <div class="teacher-information-item-cont">
      <div v-for="item in this.info.tech_direction_tree">
        <div class="teacher-information-item-cont-tit">{{item.name}}</div>
        <div class="teacher-information-item-cont-subtit" v-for="subItem in item.children">{{subItem.name}}<div v-if="subItem && subItem.children && subItem.children.length">（<p v-for="(third,index) in subItem.children">{{third.name}}<span v-if="index < subItem.children.length - 1">,</span></p>）</div></div>
      </div>
<!--      {{techDirection}}-->
    </div>
  </div>
  <div class="teacher-information-item">
    <div class="teacher-information-item-tit">
      <p class="teacher-information-item-tit-icon"></p>
      <p class="teacher-information-item-tit-name">技术体系</p>
    </div>
    <div class="teacher-information-item-cont">
      {{ techSystem }}
    </div>
  </div>
  <div class="teacher-information-item">
    <div class="teacher-information-item-tit">
      <p class="teacher-information-item-tit-icon"></p>
      <p class="teacher-information-item-tit-name">经验</p>
    </div>
    <div class="teacher-information-item-cont">
      {{ workExp }}
    </div>
  </div>
  <div class="teacher-information-item" v-if="language">
    <div class="teacher-information-item-tit">
      <p class="teacher-information-item-tit-icon"></p>
      <p class="teacher-information-item-tit-name">语言能力</p>
    </div>
    <div class="teacher-information-item-cont">
      {{ language }}
    </div>
  </div>
  <div class="teacher-information-item-fgx"></div>
  <div class="teacher-information-item" v-if="categorys">
    <div class="teacher-information-item-tit">
      <p class="teacher-information-item-tit-icon"></p>
      <p class="teacher-information-item-tit-name">资质</p>
    </div>
    <div class="teacher-information-item-cont">
      {{ categorys }}
    </div>
  </div>
  <div class="teacher-information-item" v-if="certList.length">
    <div class="teacher-information-item-tit">
      <p class="teacher-information-item-tit-icon"></p>
      <p class="teacher-information-item-tit-name">资质证书</p>
    </div>
    <div class="teacher-information-item-imgs">
      <img :src="item" width="130" height="86" v-for="(item,index) in certList" :key="index" @click="showCert(item)" />
    </div>
  </div>
  <div class="teacher-information-item" v-if="info.education_exp_arr && info.education_exp_arr.length">
    <div class="teacher-information-item-tit">
      <p class="teacher-information-item-tit-icon"></p>
      <p class="teacher-information-item-tit-name">教育经历（{{info.education_exp_arr.length}}）</p>
    </div>
    <div class="teacher-information-item-list">
      <div class="teacher-information-item-list-item" v-for="(item,index) in info.education_exp_arr" :key="index">
        <div class="time-slot">{{ item.dateStr }}</div>
        <div class="teacher-information-item-list-item-info">{{ item.schoolName }}（{{ item.major }}）</div>
      </div>
    </div>
  </div>
  <div class="teacher-information-item" v-if="info.job_exp_arr && info.job_exp_arr.length">
    <div class="teacher-information-item-tit">
      <p class="teacher-information-item-tit-icon"></p>
      <p class="teacher-information-item-tit-name">工作经历（{{info.job_exp_arr.length}}）</p>
    </div>
    <div class="teacher-information-item-list">
      <div class="teacher-information-item-list-item" v-for="(item,index) in info.job_exp_arr" :key="index + 'job'">
        <div class="time-slot">{{ item.dateStr }}</div>
        <div class="teacher-information-item-list-item-info">{{ item.companyName }}（{{ item.position }}）</div>
      </div>
    </div>
  </div>
  <div class="teacher-information-item" v-if="info.training_exp_arr && info.training_exp_arr.length">
    <div class="teacher-information-item-tit">
      <p class="teacher-information-item-tit-icon"></p>
      <p class="teacher-information-item-tit-name">培训/授课经历（{{info.training_exp_arr.length}}）</p>
    </div>
    <div class="teacher-information-item-list">
      <div class="teacher-information-item-list-item" v-for="(item,index) in info.training_exp_arr" :key="index + 'train'">
        <div class="time-slot">{{ item.dateStr }}</div>
        <div class="teacher-information-item-list-item-info">{{item.cont}}</div>
      </div>
    </div>
  </div>
  <div class="teacher-information-item" v-if="info.main_course">
    <div class="teacher-information-item-tit">
      <p class="teacher-information-item-tit-icon"></p>
      <p class="teacher-information-item-tit-name">主讲课程</p>
    </div>
    <div class="teacher-information-item-cont">
      {{info.main_course}}
    </div>
  </div>
  <div class="teacher-information-item" v-if="info.make_course && (info.make_course.uploadUrl || info.make_course.url)">
    <div class="teacher-information-item-tit">
      <p class="teacher-information-item-tit-icon"></p>
      <p class="teacher-information-item-tit-name">之前做过的课程</p>
    </div>
    <div class="teacher-information-item-file" v-if="info.make_course && info.make_course.uploadUrl">
      <img src="../../../assets/images/new_icon/wenjian.png" width="32" height="36" />
      <p>文件名：{{ info.make_course && info.make_course.uploadUrl }}</p>
      <div @click="downLoad(info.make_course.uploadUrl)">下载</div>
    </div>
    <div class="teacher-information-item-url" v-if="info.make_course && info.make_course.url">
      <p>课程文件链接:</p>
      <div>
        <p>{{info.make_course && info.make_course.url}}</p>
        <div @click="copy(info.make_course.url)">复制</div>
      </div>
    </div>
  </div>
  <div class="teacher-information-item">
    <div class="teacher-information-item-tit">
      <p class="teacher-information-item-tit-icon"></p>
      <p class="teacher-information-item-tit-name">个人简历</p>
    </div>
    <div class="teacher-information-item-file">
      <img src="../../../assets/images/new_icon/wenjian.png" width="32" height="36" />
      <p>文件名：{{ info.resume }}</p>
      <div>
        <div @click="checkResume(info.resume)">预览</div>
        <div @click="downLoad(info.resume)">下载</div>
      </div>
    </div>
  </div>

</div>
</template>

<script>
import FileSaver from "file-saver";
import util from '@/utils/tools.js';
export default {
  name: "info",
  data(){
    return{
      techDirection:'',
      techSystem:'',
      workExp:'',
      language:'',
      categorys:'',
      certList:[],
      certificateUrl:'',
      visible:false,
    }
  },
  props:{
    info:{
      type:Object,
      default:()=>{
        return {}
      }
    }
  },
  watch:{
    info:{
      handler(newV){
        if(newV){
          this.init(newV);
        }
      },
      immediate:true,
    }
  },
  methods:{
    showCert(data){
      this.$emit('showCert',data);
    },
    init(){
      let data = [];
      if(this.info.tech_direction_arr && this.info.tech_direction_arr.length){
        this.info.tech_direction_arr.forEach((item)=>{
          let arr = [];
          for(let name in item){
            if(name == 99){
              arr.push('其他-' + item[name]);
              break;
            }else{
              arr.push(item[name])
            }
          }
          data.push(arr.join('-'));
        })
      }

      this.techDirection = data.join(',');

      let systemData = [];
      for(let name in this.info.tech_system_arr){
        systemData.push(this.info.tech_system_arr[name])
      }
      this.techSystem = systemData.join(',');

      let workData = [];
      for(let name in this.info.work_exp_arr){
        workData.push(this.info.work_exp_arr[name])
      }
      this.workExp = workData.join(',');

      let languageData = [];
      for(let name in this.info.language_ability_arr){
        languageData.push(this.info.language_ability_arr[name])
      }
      this.language = languageData.join(',');

      let categoryArr = [];
      if(this.info.auth_category_names && this.info.auth_category_names.length){
        this.info.auth_category_names.forEach((item)=>{
          let arr = [];
          for(let name in item){
            if(name == 99){
              arr.push('其他-' + item[name]);
              break;
            }else if(name == 0){
              arr.push('暂无资质');
              break;
            }else{
              arr.push(item[name])
            }
          }
          categoryArr.push(arr.join('-'));
        })
      }

      this.categorys = categoryArr.join(',');


      this.certList = this.info.auth_cert ? this.info.auth_cert.split(',') : [];

    },
    downLoad(data){
      // util.downloadFile(this,data);
      let arr = data.split('show/?file=');
      if(arr[1]){
        let uploadParams = {
          file:arr[1]
        };
        this.api.codekey.uploadfileDownload(uploadParams).then((res)=>{
          util.downLoad(res);
        })
      }else{
        let name = data.split('/');
        name = name[name.length -1].split(/\.(?=[^\.]+$)/);
        name = name[0] + '.' + name[1];
        FileSaver.saveAs(data,name);
      }
    },
    copy(data){
      this.$copyText(data).then( (e)=>{
        this.$Message.success({
          content:'复制成功',
        });
      }, (e)=> {
        this.$Message.success('复制失败');
      })
    },
    checkResume(data){
      window.open(data,'_blank')
    },
  }
}
</script>

<style scoped lang="scss">
.teacher-information{
  .teacher-information-item{
    margin-top: 20px;
    .teacher-information-item-tit{
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .teacher-information-item-tit-icon{
        margin-right: 5px;
        width: 4px;
        height: 16px;
        background: #4A79FF;
      }
      .teacher-information-item-tit-name{
        font-size: 16px;
        font-weight: bold;
        color: #333333;
      }
      .teacher-information-item-tit-tag{
        margin-left: 14px;
        padding: 0 10px;
        height: 24px;
        background: #E7FFF4;
        border-radius: 4px;
        color: #46C59A;
        display: flex;
        align-items: center;
      }
      .red{
        background: #FFE6E5;
        color: #FC605C;
      }
    }
    .teacher-information-item-cont{
      margin-left: 10px;
      margin-top: 8px;
      color: #666666;
      line-height: 24px;

      .teacher-information-item-cont-tit{
        font-size: 16px;
        font-weight: bold;
        margin: 10px 0;
      }
      .teacher-information-item-cont-subtit{
        padding-left: 20px;
        display: flex;
        justify-content: flex-start;
        >div{
          display: flex;
          justify-content: flex-start;
        }
      }
    }
    .teacher-information-item-imgs{
      margin-top: 10px;
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      >img{
        margin-right: 10px;
      }
      >img:hover{
        cursor: url("../../../assets/images/new_icon/fangda.png"), auto;
      }
    }
    .teacher-information-item-list{
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      .teacher-information-item-list-item{
        margin-top: 10px;
        margin-right: 10px;
        padding: 20px;
        width: 340px;
        background: #F6F7FA;
        border-radius: 6px;
      }
    }
    .teacher-information-item-file{
      margin-top: 10px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      >div{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        >div{
          margin-left: 10px;
          width: 65px;
          height: 28px;
          background: #5578F6;
          border-radius: 4px;
          color: #FFFFFF;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
        }

      }
      >p{
        margin-left: 6px;
        margin-right: 14px;
        flex: 1;
        width: 0;
        word-break: break-all;
      }
    }
    .teacher-information-item-url{
      margin-top: 10px;
      padding: 20px;
      background: #F6F7FA;
      border-radius: 6px;
      >div{
        display: flex;
        justify-content: flex-start;
        >p{
          flex: 1;
          width: 0;
          word-break: break-all;
        }
        >div{
          margin-left: 14px;
          width: 50px;
          height: 20px;
          background: #5578F6;
          border-radius: 4px;
          color:#FFFFFF;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 12px;
          cursor: pointer;
        }
      }
    }
  }
  .teacher-information-item-fgx{
    margin: 30px 0;
    width: 100%;
    height: 1px;
    background: #EEEEEE;
  }
}
</style>
