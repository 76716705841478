<template>
<div >
  <div style="margin: 20px 0 0 20px">
    <MyBreadcrumb></MyBreadcrumb>
  </div>
  <div class="authentication-verify">
    <div class="top">
      <div style="display: flex;justify-content: flex-start;align-items: center">
        <Button type="primary" style="margin-right: 10px" @click="add">手动添加</Button>
        <!-- <Button type="primary" @click="showExportModal" style="margin-right: 10px">导入多个简历</Button> -->
        <Button type="primary" @click="showZipExportModal" style="margin-right: 10px">导入压缩包</Button>
<!--        <Button type="primary" @click="goStatistics" style="margin-right: 10px">师资注册量统计</Button>-->
        <Button type="primary" @click="goNotice" style="margin-right: 10px">师资消息通知</Button>
        <Button type="primary" @click="invite" style="margin-right: 10px">邀请认证</Button>
        <Button type="primary" @click="aiDrawer=true" style="margin-right: 10px">智能搜索</Button>
      </div>
    </div>
    <div class="teacher-tab">
      <div :class="curStatusIndex == index ? 'active' : ''" v-for="(item,index) in statusesArr" :key="item.id"  @click="changeSearch(index,'curStatusIndex',item)">{{ item.name }}</div>
    </div>
    <div class="search">
      <div class="search-item">
        <div class="name">
          搜索:
        </div>
        <div class="cont">
          <el-input
              :placeholder="$t('user_search_tip')"
              prefix-icon="el-icon-search"
              v-model="keyword"
              @change="searchList"
              style="width: 200px"
          >
          </el-input>
        </div>
      </div>
      <div class="search-item">
        <div class="name">
          技术方向:
        </div>
        <div class="cont">
          <el-cascader :options="techDirection" collapse-tags v-model="techDirectionId" :props="optionProps" clearable style="width: 200px;" @change="getList" ></el-cascader>
        </div>
      </div>
      <div class="search-item">
        <div class="name">
          常驻地:
        </div>
        <div class="cont">
          <el-cascader :options="cityList" collapse-tags v-model="cityId" :props="cityOptionProps" clearable style="width: 200px;" @change="getList"></el-cascader>
        </div>
      </div>
      <div class="search-item">
        <div class="name">
          所获资质:
        </div>
        <div class="cont">
          <el-cascader :options="categoryList" v-model="curCategory" :props="categoryOptionProps" clearable collapse-tags style="width: 200px;" @change="getList"></el-cascader>
        </div>
      </div>
      <div class="search-item">
        <div class="name">
          经验:
        </div>
        <div class="cont">
          <el-select v-model="curWorkExp" multiple collapse-tags clearable placeholder="请选择" style="width: 200px;" @change="getList">
            <el-option
                v-for="item in workExp"
                :key="item.id"
                :label="item.name"
                :value="item.id">
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="search-item">
        <div class="name">
          语言:
        </div>
        <div class="cont">
          <el-select v-model="curLanguage" multiple collapse-tags clearable placeholder="请选择" style="width: 200px;" @change="getList">
            <el-option
                v-for="item in languageList"
                :key="item.id"
                :label="item.name"
                :value="item.id">
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="search-item">
        <div class="name">
          时间安排:
        </div>
        <div class="cont">
          <el-select v-model="curTime" multiple collapse-tags clearable placeholder="请选择" style="width: 200px;" @change="getList">
            <el-option
                v-for="item in timeList"
                :key="item.id"
                :label="item.name"
                :value="item.id">
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="search-item">
        <div class="name">
          技术体系:
        </div>
        <div class="cont">
          <el-select v-model="curTechSystem" multiple collapse-tags clearable placeholder="请选择" style="width: 200px;" @change="getList">
            <el-option
                v-for="item in techSystem"
                :key="item.id"
                :label="item.name"
                :value="item.id">
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="search-item">
        <div class="name">
          学历:
        </div>
        <div class="cont">
          <el-select v-model="curDegree" multiple collapse-tags clearable placeholder="请选择" style="width: 200px;" @change="getList">
            <el-option
                v-for="item in degreeList"
                :key="item.id"
                :label="item.name"
                :value="item.id">
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="search-item">
        <div class="name">
          近期合作:
        </div>
        <div class="cont">
          <el-select v-model="curActive" clearable placeholder="请选择" @change="getList" style="width: 200px;">
            <el-option
                v-for="item in activeTimeTypeArr"
                :key="item.id"
                :label="item.name"
                :value="item.id">
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="search-item">
        <div class="name">
          关注公众号:
        </div>
        <div class="cont">
          <el-select v-model="curWeixinSubscribe" clearable placeholder="请选择" style="width: 200px;" @change="getList">
            <el-option
                v-for="item in weixinSubscribes"
                :key="item.is"
                :label="item.name"
                :value="item.id">
            </el-option>
          </el-select>
        </div>
      </div>

    </div>
    <div class="table">
      <Table :columns="(statusesArr.length && statusesArr[curStatusIndex].id == '0') ? unCheckColumns :columns" :data="data" :loading="loading"></Table>
    </div>
    <div class="page">
      <Page :total="total" @on-change="changePage" @on-page-size-change="changePageSize" :current="page" show-total show-sizer show-elevator></Page>
    </div>
  </div>

  <!-- <Modal
      v-model="authCertModal"
      title="查看技术认证证书	"
      @on-cancel="closeAuthCertModal"
  >
    <div class="swiper-container" v-if="authCertModal">
      <div class="swiper-wrapper">
        <div class="swiper-slide"
             v-for="(item,index) in imgList"
             :key="index">
          <img :src="item"  alt="" width="100%" height="400px"/>
        </div>
      </div>
      <div class="swiper-pagination"></div>
    </div>
  </Modal> -->
  <el-drawer :size="800" :visible.sync="authCertModal">
    <div class="drawer-title" slot="title">
      <div class="drawer-title-text">查看技术认证证书</div>
      <div class="drawer-title-btns">
        <el-button size="small"  @click="closeAuthCertModal">取消</el-button>
      </div>
    </div>
    <div style="padding: 20px;">
      <div class="swiper-container" v-if="authCertModal">
        <div class="swiper-wrapper">
          <div class="swiper-slide"
              v-for="(item,index) in imgList"
              :key="index">
            <img :src="item"  alt="" width="100%" height="400px"/>
          </div>
        </div>
        <div class="swiper-pagination"></div>
      </div>
    </div>
  </el-drawer>

  <!-- <Modal
      v-model="verifyReasonModal"
      title="审核不通过"
      @on-cancel="closeVerifyReason"
      @on-ok="confirmVerifyReason"
  >
    <Input v-model="verifyReason" :maxlength="200" type="textarea" :autosize="{minRows: 5,maxRows: 5}" placeholder="请输入原因" />
  </Modal> -->
  <el-drawer :size="800" :visible.sync="verifyReasonModal">
    <div class="drawer-title" slot="title">
      <div class="drawer-title-text">审核不通过</div>
      <div class="drawer-title-btns">
        <el-button size="small" style="margin-right: 10px;" @click="closeVerifyReason">取消</el-button>
        <el-button type="primary" size="small" @click="confirmVerifyReason">确定</el-button>
      </div>
    </div>
    <div style="padding: 20px;">
      <Input v-model="verifyReason" :maxlength="200" type="textarea" :autosize="{minRows: 5,maxRows: 5}" placeholder="请输入原因" />
    </div>
  </el-drawer>


  <Modal
      v-model="exportFileModal"
      title="导入多个简历"
      @on-cancel="closeExportFileModal"
  >
    <Upload
        type="drag"
        :action="constant.URL + '/uploadfile/upload'"
        :headers="{
                  'access-token':Cookies.get(constant.tokenName)
                }"
        :data="{
                  type:3
                }"
        accept=".pdf"
        :format="['pdf']"
        :on-success="uploadFileSuccess"
        :show-upload-list="false"
        multiple
    >
      <div class="upload-cont-info">

        <div class="upload-cont-info-message">
          <Button type="primary" icon="ios-cloud-upload-outline" style="margin-bottom: 10px">上传简历pdf</Button>
<!--          <p class="down-load" @click.stop="downLoadFile">下载模版</p>-->
          <p>点击按钮选择试题文件，或拖拽试题文件到此区域</p>
          <p>支持文件格式：.pdf</p>
        </div>
      </div>
    </Upload>
  </Modal>
  <!-- <Modal
      v-model="exportZipFileModal"
      title="导入压缩包"
      @on-cancel="closeZipExportFileModal"
  >
    <Upload
        type="drag"
        :action="constant.URL + '/uploadfile/upload'"
        :headers="{
                  'access-token':Cookies.get('liteAccessToken')
                }"
        :data="{
                  type:0
                }"
        accept=".zip"
        :format="['zip']"
        :on-success="uploadZipFileSuccess"
        :show-upload-list="false"
        multiple
    >
      <div class="upload-cont-info">

        <div class="upload-cont-info-message">
          <Button type="primary" icon="ios-cloud-upload-outline" style="margin-bottom: 10px">上传文件</Button>
          <p class="down-load" @click.stop="downLoadFile">下载模版</p>
          <p>点击按钮选择试题文件，或拖拽试题文件到此区域</p>
          <p>支持文件格式：.zip</p>
        </div>
      </div>
    </Upload>
  </Modal> -->
  <el-drawer :size="800" :visible.sync="exportZipFileModal" @close="closeZipExportFileModal">
    <div class="drawer-title" slot="title">
      <div class="drawer-title-text">导入压缩包</div>
    </div>
    <div style="padding: 20px;">
      <Upload
        type="drag"
        :action="constant.URL + '/uploadfile/upload'"
        :headers="{
                  'access-token':Cookies.get(constant.tokenName)
                }"
        :data="{
                  type:0
                }"
        accept=".zip"
        :format="['zip']"
        :on-success="uploadZipFileSuccess"
        :show-upload-list="false"
        multiple
    >
      <div class="upload-cont-info">

        <div class="upload-cont-info-message">
          <Button type="primary" icon="ios-cloud-upload-outline" style="margin-bottom: 10px">上传文件</Button>
          <p class="down-load" @click.stop="downLoadFile">下载模版</p>
          <p>点击按钮选择试题文件，或拖拽试题文件到此区域</p>
          <p>支持文件格式：.zip</p>
        </div>
      </div>
    </Upload>
    </div>
  </el-drawer>

  <el-drawer
      title="我是标题"
      :visible.sync="drawer"
      :with-header="false"
      :size="1000"
      >
    <Detail v-if="drawer" :info="curData" :degreeList="degreeList" @deleSuccess="deleSuccess" @opSuccess="opSuccess" :followTypes="followTypes" @close="closeDrawer" @showCert="showCert"></Detail>
  </el-drawer>
  <div v-if="createFollowStatus">
    <CreateFollow @success="followSuccess" @close="followClose" :followTypes="followTypes" :userId="curData.user.id"></CreateFollow>
  </div>
  <!-- <Modal
      v-model="inviteModal"
      title="创建邀请链接"
      @on-cancel="inviteModal = false"
  >
    <div class="pc-url">
      <p>链接邀请方式：</p>
      <div style="margin-bottom: 10px">{{ inviteUrl }}</div>
      <Button type="primary" @click="doCopy(inviteUrl)" style="margin-right: 20px">复制链接</Button>
      <Button type="primary" @click="exportImg">下载邀请海报</Button>
    </div>
    <img :src="inviteImg"  width="490"  />
  </Modal> -->
  <div v-if="visible">
    <el-dialog title="查看" :visible.sync="visible" width="500px">
      <img :src="certificateUrl" v-if="visible" style="width: 100%">
    </el-dialog>
  </div>
  <!-- 邀请认证抽屉 -->
  <el-drawer :size="800" :visible.sync="inviteModal" @close="inviteModal = false">
    <div class="drawer-title" slot="title">
      <div class="drawer-title-text">创建邀请链接</div>
    </div>
    <div style="padding: 20px;">
      <div class="pc-url">
        <p>链接邀请方式：</p>
        <div style="margin-bottom: 10px">{{ inviteUrl }}</div>
        <Button type="primary" @click="doCopy(inviteUrl)" style="margin-right: 20px">复制链接</Button>
        <Button type="primary" @click="exportImg">下载邀请海报</Button>
      </div>
      <img :src="inviteImg"  width="490"  />
    </div>
  </el-drawer>
  <TeacherAuth :show="teacherAuthShow" @close="teacherAuthClose()"/>

  <el-drawer :visible.sync="aiDrawer" :size="1000">
    <div class="drawer-title" slot="title">
      <div class="drawer-title-text">智能搜索</div>
    </div>
    <div class="ai-tips">
      <div>提示语格式：请帮我推荐10名擅长大数据专业技术的人，提供他们的姓名，电话和专业背景</div>
      <el-button type="primary" size="mini" v-clipboard="'请帮我推荐10名擅长大数据专业技术的人，提供他们的姓名，电话和专业背景'">复制</el-button>
    </div>
    <div class="ai-search">
      <el-input v-model="aiKeyword" clearable placeholder="请输入关键字,如:大数据,人工智能" size="small"></el-input>
      <el-button size="small" type="primary" @click="aiSearch()">搜索</el-button>
    </div>
    <div class="ai-text" ref="aiText">
      <el-skeleton :loading="aiLoading" :rows="6" animated >
        <template>
          {{ typeText }}<span v-if="textIndex+1<aiText.length">_</span>
        </template>
      </el-skeleton>
    </div>
  </el-drawer>

</div>
</template>

<script>
import util from '@/utils/tools.js';
import FileSaver from "file-saver";

import Cookies from "js-cookie";
import Detail from './components/detail'
import CreateFollow from './components/createFollow'
import TeacherAuth from "./components/teacherAuth.vue";
import {apiBase} from '@/router/init';
export default {
  name: "authenticationVerify.vue",
  data(){
    return {
      Cookies:Cookies,
      teacherAuthShow:false,
      keyword:'',
      tabList:[],
      curTab:'',
      columns:[
        {
          title: 'ID',
          key: 'id',
          fixed: 'left',
          width:80,
        },
        {
          title: '真实姓名',
          key:'user_id',
          fixed: 'left',
          width:160,
          render:(h,params)=>{
            return h('span', params.row.user && ((params.row.user.realname ||params.row.user.nickname) + '(id:'+params.row.user.id+')' ));
          }
        },
        {
          title: '所在城市',
          key: 'mobile',
          width:140,
          render:(h,params)=>{
            let arr = [];
            if(params.row.province && params.row.province.name){
              arr.push(params.row.province.name)
            }
            if(params.row.city && params.row.city.name){
              arr.push(params.row.city.name)
            }
            return h('span', arr.join('-'));
          }
        },
        {
          title: '电话',
          key: 'mobile',
          width:120,
          render:(h,params)=>{
            return h('span', params.row.user && params.row.user.mobile);
          }
        },
        // {
        //   title: '支付宝账号',
        //   key: 'ali_account',
        //   width:120,
        // },
        // {
        //   title: '微信号',
        //   key: 'weixin',
        //   width: 120,
        // },
        {
          title: '技术方向',
          key: 'tech_direction',
          minWidth:140,
          tooltip:true,
          render:(h,params)=>{
            let data = [];
            if( params.row.tech_direction_arr && params.row.tech_direction_arr.length){
              params.row.tech_direction_arr.forEach((item)=>{
                let arr = [];
                for(let name in item){
                  if(name == 99){
                    arr.push('其他-' + item[name]);
                    break;
                  }else{
                    arr.push(item[name])
                  }

                }
                data.push(arr);
              })
            }
            // return h('span', data.join(','));
            let texts=data.join(",");
            if(texts.length>38){
              texts=texts.substring(0,38)+"...";
            }


            let hDiv = [];
            let hTexts = [];
            if( params.row.tech_direction_tree && params.row.tech_direction_tree.length){
              params.row.tech_direction_tree.forEach((item)=>{
                let shDiv = [];
                item.children.forEach((sItem)=>{
                  let thirdNames = sItem.children ?  sItem.children.map((third)=>{
                    return third.name;
                  }).join(',') : '';
                  thirdNames = thirdNames ? '（' + thirdNames + '）' : '';
                  shDiv.push(h('p',{
                    style:{
                      wordBreak:"break-all",
                      whiteSpace:"normal",
                      paddingLeft:'15px',
                      fontWeight:'normal'
                    }
                  },sItem.name + thirdNames))
                })
                hDiv.push(h("div",{
                  slot:"content",
                  style:{
                    wordBreak:"break-all",
                    whiteSpace:"normal",
                    fontWeight:'bold'
                  }
                },[
                  '【' + item.name + '】',
                  shDiv
                ]))
                hTexts.push(h("div",{
                  style:{
                    wordBreak:"break-all",
                    whiteSpace:"normal",
                    fontWeight:'bold'
                  }
                },[
                  '【' + item.name + '】',
                  shDiv
                ]));
              })
            }

            return h("Tooltip",{
              props:{
                placement:"bottom-start",
                transfer:true
              },
              class:{
                "teacher-list":true
              }
            },[
              hTexts,
              h("div",{
                slot:"content",
                style:{
                  wordBreak:"break-all",
                  whiteSpace:"normal"
                }
              },hDiv)
            ])
          }
        },
        {
          title: '技术体系',
          key: 'tech_system',
          minWidth:140,
          render:(h,params)=>{
            let data = [];
            for(let name in params.row.tech_system_arr){
              data.push(params.row.tech_system_arr[name])
            }
            // return h('span', data.join(','));
            return util.tableColumnTooltip(h,data.join(','));
          }
        },
        {
          title: '合作次数',
          key: 'cooperation_num',
          width: 120
        },
        {
          title: '简历',
          key: 'resume',
          width:140,
          render:(h,params)=>{
            return h('div', [
              h('Button', {
                props: {
                  type: 'text',
                  size: 'small'
                },
                style: {
                  marginRight: '5px',
                  color:'#2d8cf0'
                },
                on: {
                  click: () => {
                    this.checkResume(params.row.resume)
                  }
                }
              }, '查看'),
              h('Button', {
                props: {
                  type: 'text',
                  size: 'small'
                },
                style: {
                  marginRight: '5px',
                  color:'#2d8cf0'
                },
                on: {
                  click: () => {
                    this.downLoad(params.row.resume);
                  }
                }
              }, '下载'),
            ]);
          }
        },
        {
          title: '证书',
          key: 'auth_cert',
          width:140,
          render:(h,params)=>{
            return h('div', [
              h('Button', {
                props: {
                  type: 'text',
                  size: 'small'
                },
                style: {
                  marginRight: '5px',
                  color:'#2d8cf0'
                },
                on: {
                  click: () => {
                    this.imgList = params.row.auth_cert.split(',');
                    this.authCertModal = true;
                    this.showSwiper();
                  }
                }
              }, '查看'),
              h('Button', {
                props: {
                  type: 'text',
                  size: 'small'
                },
                style: {
                  marginRight: '5px',
                  color:'#2d8cf0'
                },
                on: {
                  click: () => {
                    let arr = params.row.auth_cert.split(',');
                    arr.forEach((item)=>{
                      this.downLoad(item);
                    })

                  }
                }
              }, '下载'),
            ]);
          }
        },

        // {
        //   title: '关注公众号',
        //   key: 'remarks',
        //   width:140,
        //   render:(h,params)=>{
        //     return h('span', this.weixinSubscribesObj[params.row.weixin_subscribe]);
        //   }
        // },
        // {
        //   title: '状态',
        //   key: 'status',
        //   width:120,
        //   render:(h,params)=>{
        //     if (params.row.status == 5){
        //       return h('span', this.statuses[params.row.status] + '('+params.row.resume_parse_message+')');
        //     }else{
        //       return h('span', this.statuses[params.row.status]);
        //     }
        //
        //   }
        // },
        // {
        //   title: '添加时间',
        //   key: 'created_at',
        //   width:140,
        //   render:(h,params)=>{
        //     return h('span', util.timeFormatter(new Date(+params.row.created_at*1000), 'yyyy-MM-dd hh:mm'));
        //   }
        // },
        // {
        //   title: '更新时间',
        //   key: 'updated_at',
        //   width:140,
        //   render:(h,params)=>{
        //     return h('span', util.timeFormatter(new Date(+params.row.updated_at*1000), 'yyyy-MM-dd hh:mm'));
        //   }
        // },
        {
          title: '操作',
          key: 'op',
          fixed: 'right',
          width:250,
          render:(h,params)=>{
            return h('div', [
              this.statusesArr[this.curStatusIndex].id == 0 ? h('Button', {
                props: {
                  type: 'primary',
                  size: 'small',
                },
                style: {
                  marginRight: '5px',
                  marginBottom:'5px'
                },
                on: {
                  click: () => {
                    this.teacherVerify(params.row.user_id,1)
                  }
                }
              }, '审核通过') : '',
              this.statusesArr[this.curStatusIndex].id == 0 ? h('Button', {
                props: {
                  type: 'error',
                  size: 'small'
                },
                style: {
                  marginRight: '5px',
                  marginBottom:'5px'
                },
                on: {
                  click: () => {
                    this.curData = params.row;
                    this.verifyReasonModal = true;
                  }
                }
              }, '审核不通过') : '',
              h('Button', {
                props: {
                  type: 'primary',
                  size: 'small',
                  disabled:params.row.user_id == 0
                },
                style: {
                  marginRight: '5px',
                  marginBottom:'5px'
                },
                on: {
                  click: () => {
                    this.curData = params.row;
                    this.check(params.row.user_id,params.row.user && params.row.user.nickname)
                  }
                }
              }, '查看详情'),
              h('Button', {
                props: {
                  type: 'primary',
                  size: 'small',
                  disabled:params.row.user_id == 0
                },
                style: {
                  marginRight: '5px',
                  marginBottom:'5px'
                },
                on: {
                  click: () => {
                    this.curData = params.row;
                    this.follow()
                  }
                }
              }, '跟进'),
              this.statusesArr[this.curStatusIndex].id == 99 ? h('Button', {
                props: {
                  type: 'primary',
                  size: 'small',
                  disabled:params.row.user_id == 0
                },
                style: {
                  marginRight: '5px',
                  marginBottom:'5px'
                },
                on: {
                  click: () => {
                    this.curData = params.row;
                    this.removeBlackList()
                  }
                }
              }, '移除黑名单') : '',
              params.row.status == '1' ? h('Button', {
                props: {
                  type: 'primary',
                  size: 'small',
                  disabled:params.row.user_id == 0
                },
                style: {
                  marginRight: '5px',
                  marginBottom:'5px'
                },
                on: {
                  click: () => {
                    this.curData = params.row;
                    if(params.row.top_time > 0){
                      this.cancelTop(params.row)
                    }else{
                      this.setTop(params.row)
                    }

                  }
                }
              }, params.row.top_time > 0 ? '取消置顶' : '置顶') : '',
              // h('Button', {
              //   props: {
              //     type: 'primary',
              //     size: 'small',
              //     disabled:params.row.user_id == 0
              //   },
              //   style: {
              //     marginRight: '5px',
              //     marginBottom:'5px'
              //   },
              //   on: {
              //     click: () => {
              //       this.edit(params.row.user_id,params.row.user && params.row.user.nickname)
              //     }
              //   }
              // }, '编辑'),
              // h('Button', {
              //   props: {
              //     type: 'primary',
              //     size: 'small',
              //     disabled:params.row.user_id == 0
              //   },
              //   style: {
              //     marginRight: '5px',
              //     marginBottom:'5px'
              //   },
              //   on: {
              //     click: () => {
              //       this.downLoad(params.row.resume);
              //       let arr = params.row.auth_cert.split(',');
              //       arr.forEach((item)=>{
              //         this.downLoad(item);
              //       })
              //     }
              //   }
              // }, '下载全部资料'),
              //
              // h('Button', {
              //   props: {
              //     type: 'primary',
              //     size: 'small',
              //   },
              //   style: {
              //     marginRight: '5px',
              //     marginBottom:'5px'
              //   },
              //   on: {
              //     click: () => {
              //       this.curData = params.row;
              //       this.deleModalStatus = true;
              //     }
              //   }
              // }, '删除'),
            ]);
          }
        },
      ],
      unCheckColumns:[
        {
          title: 'ID',
          key: 'id',
          fixed: 'left',
          width:80,
        },
        {
          title: '真实姓名',
          key:'user_id',
          fixed: 'left',
          width:160,
          render:(h,params)=>{
            return h('span', params.row.user && ((params.row.user.realname ||params.row.user.nickname) + '(id:'+params.row.user.id+')' ));
          }
        },
        {
          title: '所在城市',
          key: 'mobile',
          width:140,
          render:(h,params)=>{
            let arr = [];
            if(params.row.province && params.row.province.name){
              arr.push(params.row.province.name)
            }
            if(params.row.city && params.row.city.name){
              arr.push(params.row.city.name)
            }
            return h('span', arr.join('-'));
          }
        },
        {
          title: '电话',
          key: 'mobile',
          width:120,
          render:(h,params)=>{
            return h('span', params.row.user && params.row.user.mobile);
          }
        },
        // {
        //   title: '支付宝账号',
        //   key: 'ali_account',
        //   width:120,
        // },
        // {
        //   title: '微信号',
        //   key: 'weixin',
        //   width: 120,
        // },
        {
          title: '技术方向',
          key: 'tech_direction',
          minWidth:140,
          tooltip:true,
          render:(h,params)=>{
            let data = [];
            if( params.row.tech_direction_arr && params.row.tech_direction_arr.length){
              params.row.tech_direction_arr.forEach((item)=>{
                let arr = [];
                for(let name in item){
                  if(name == 99){
                    arr.push('其他-' + item[name]);
                    break;
                  }else{
                    arr.push(item[name])
                  }

                }
                data.push(arr);
              })
            }
            // return h('span', data.join(','));
            let texts=data.join(",");
            if(texts.length>38){
              texts=texts.substring(0,38)+"...";
            }
            let hDiv = [];
            let hTexts = [];
            if( params.row.tech_direction_tree && params.row.tech_direction_tree.length){
              params.row.tech_direction_tree.forEach((item)=>{
                let shDiv = [];
                item.children && item.children.forEach((sItem)=>{
                  let thirdNames = sItem.children ? sItem.children.map((third)=>{
                    return third.name;
                  }).join(',') : '';
                  thirdNames = thirdNames ? '（' + thirdNames + '）' : '';
                  shDiv.push(h('p',{
                    style:{
                      wordBreak:"break-all",
                      whiteSpace:"normal",
                      paddingLeft:'15px',
                      fontWeight:'normal'
                    }
                  },sItem.name + thirdNames))
                })
                hDiv.push(h("div",{
                  slot:"content",
                  style:{
                    wordBreak:"break-all",
                    whiteSpace:"normal",
                    fontWeight:'bold'
                  }
                },[
                    '【' + item.name + '】',
                    shDiv
                ]));
                hTexts.push(h("div",{
                  style:{
                    wordBreak:"break-all",
                    whiteSpace:"normal",
                    fontWeight:'bold'
                  }
                },[
                  '【' + item.name + '】',
                  shDiv
                ]));
              })
            }

            console.log(hDiv,'hDivhDiv=========')
            return h("Tooltip",{
              props:{
                placement:"bottom-start",
                transfer:true
              },
              class:{
                "teacher-list":true
              }
            },[
              hTexts,
              hDiv
            ])
          }
        },
        {
          title: '技术体系',
          key: 'tech_system',
          minWidth:140,
          render:(h,params)=>{
            let data = [];
            for(let name in params.row.tech_system_arr){
              data.push(params.row.tech_system_arr[name])
            }
            return h('span', data.join(','));
          }
        },
        {
          title: '合作次数',
          key: 'cooperation_num',
          width: 120
        },
        {
          title: '简历',
          key: 'resume',
          width:140,
          render:(h,params)=>{
            return h('div', [
              h('Button', {
                props: {
                  type: 'text',
                  size: 'small'
                },
                style: {
                  marginRight: '5px',
                  color:'#2d8cf0'
                },
                on: {
                  click: () => {
                    this.checkResume(params.row.resume)
                  }
                }
              }, '查看'),
              h('Button', {
                props: {
                  type: 'text',
                  size: 'small'
                },
                style: {
                  marginRight: '5px',
                  color:'#2d8cf0'
                },
                on: {
                  click: () => {
                    this.downLoad(params.row.resume);
                  }
                }
              }, '下载'),
            ]);
          }
        },
        {
          title: '证书',
          key: 'auth_cert',
          width:140,
          render:(h,params)=>{
            return h('div', [
              h('Button', {
                props: {
                  type: 'text',
                  size: 'small'
                },
                style: {
                  marginRight: '5px',
                  color:'#2d8cf0'
                },
                on: {
                  click: () => {
                    this.imgList = params.row.auth_cert.split(',');
                    this.authCertModal = true;
                    this.showSwiper();
                  }
                }
              }, '查看'),
              h('Button', {
                props: {
                  type: 'text',
                  size: 'small'
                },
                style: {
                  marginRight: '5px',
                  color:'#2d8cf0'
                },
                on: {
                  click: () => {
                    let arr = params.row.auth_cert.split(',');
                    arr.forEach((item)=>{
                      this.downLoad(item);
                    })

                  }
                }
              }, '下载'),
            ]);
          }
        },
        {
          title: '提交审核时间',
          key: 'created_at',
          width:140,
          render:(h,params)=>{
            return h('span', util.timeFormatter(new Date(+params.row.updated_at*1000), 'yyyy-MM-dd hh:mm'));
          }
        },
        {
          title: '操作',
          key: 'op',
          fixed: 'right',
          width:250,
          render:(h,params)=>{
            return h('div', [
              this.statusesArr[this.curStatusIndex].id == 0 ? h('Button', {
                props: {
                  type: 'primary',
                  size: 'small',
                },
                style: {
                  marginRight: '5px',
                  marginBottom:'5px'
                },
                on: {
                  click: () => {
                    this.teacherVerify(params.row.user_id,1)
                  }
                }
              }, '审核通过') : '',
              this.statusesArr[this.curStatusIndex].id == 0 ? h('Button', {
                props: {
                  type: 'error',
                  size: 'small'
                },
                style: {
                  marginRight: '5px',
                  marginBottom:'5px'
                },
                on: {
                  click: () => {
                    this.curData = params.row;
                    this.verifyReasonModal = true;
                  }
                }
              }, '审核不通过') : '',
              h('Button', {
                props: {
                  type: 'primary',
                  size: 'small',
                  disabled:params.row.user_id == 0
                },
                style: {
                  marginRight: '5px',
                  marginBottom:'5px'
                },
                on: {
                  click: () => {
                    this.curData = params.row;
                    this.check(params.row.user_id,params.row.user && params.row.user.nickname)
                  }
                }
              }, '查看详情'),
              h('Button', {
                props: {
                  type: 'primary',
                  size: 'small',
                  disabled:params.row.user_id == 0
                },
                style: {
                  marginRight: '5px',
                  marginBottom:'5px'
                },
                on: {
                  click: () => {
                    this.curData = params.row;
                    this.follow()
                  }
                }
              }, '跟进'),
              this.statusesArr[this.curStatusIndex].id == 99 ? h('Button', {
                props: {
                  type: 'primary',
                  size: 'small',
                  disabled:params.row.user_id == 0
                },
                style: {
                  marginRight: '5px',
                  marginBottom:'5px'
                },
                on: {
                  click: () => {
                    this.curData = params.row;
                    this.removeBlackList()
                  }
                }
              }, '移除黑名单') : '',
              // h('Button', {
              //   props: {
              //     type: 'primary',
              //     size: 'small',
              //     disabled:params.row.user_id == 0
              //   },
              //   style: {
              //     marginRight: '5px',
              //     marginBottom:'5px'
              //   },
              //   on: {
              //     click: () => {
              //       this.edit(params.row.user_id,params.row.user && params.row.user.nickname)
              //     }
              //   }
              // }, '编辑'),
              // h('Button', {
              //   props: {
              //     type: 'primary',
              //     size: 'small',
              //     disabled:params.row.user_id == 0
              //   },
              //   style: {
              //     marginRight: '5px',
              //     marginBottom:'5px'
              //   },
              //   on: {
              //     click: () => {
              //       this.downLoad(params.row.resume);
              //       let arr = params.row.auth_cert.split(',');
              //       arr.forEach((item)=>{
              //         this.downLoad(item);
              //       })
              //     }
              //   }
              // }, '下载全部资料'),
              //
              // h('Button', {
              //   props: {
              //     type: 'primary',
              //     size: 'small',
              //   },
              //   style: {
              //     marginRight: '5px',
              //     marginBottom:'5px'
              //   },
              //   on: {
              //     click: () => {
              //       this.curData = params.row;
              //       this.deleModalStatus = true;
              //     }
              //   }
              // }, '删除'),
            ]);
          }
        },
      ],
      data:[],
      loading:false,
      total:0,
      page:1,
      pageSize:10,
      imgList:[],
      authCertModal:false,
      swiper:'',
      verifyReasonModal:false,
      verifyReason:'',
      curData:{},
      statuses:{},
      techDirection:[],
      techSystem:[],
      workExp:[],
      statusesArr:[],
      curStatusIndex:1,
      curTechSystem:0,
      curTechDirectionIndex:0,
      curWorkExp:0,
      page:1,
      pageSize:10,
      total:0,

      uploadfileId:'',
      exportFileModal:false,
      exportZipFileModal:false,
      weixinSubscribes:[],
      curWeixinSubscribe:'',
      drawer: false,
      cityList: [],
      cityId:[],
      optionProps: {
        value: "id",
        label: "name",
        children: "children",
        multiple: true,
        expandTrigger: 'hover'
        // checkStrictly: true
      },
      categoryOptionProps: {
        value: "child_id",
        label: "name",
        children: "children",
        multiple: true,
        expandTrigger: 'hover'
        // checkStrictly: true
      },
      cityOptionProps: {
        value: "id",
        label: "name",
        children: "children",
        checkStrictly: true,
        expandTrigger: 'hover'
      },
      techDirectionId:[],
      categoryList:[],
      curCategory:'',
      languageList:[],
      curLanguage:'',
      timeList:[],
      curTime:'',
      curDegree:'',
      degreeList:[],
      activeTimeTypeArr:[],
      curActive:'',
      degreeObj:{},
      followTypes:[],
      createFollowStatus:false,
      inviteModal:false,
      inviteUrl:'',
      inviteImg:'',
      is_blacklist:0,
      certificateUrl:'',
      visible:false,

      aiLoading:false,
      eventSource:null,
      aiDrawer:false,
      aiKeyword:"",
      aiText:"",
      typeText:"",
      textIndex:0,
      aiTimer:null
    }
  },
  created(){
    this.getTeacherForm();
    this.getPlaceList();
    this.getCategoryTree();
  },
  beforeDestroy(){
    this.closeSSE();
  },
  components:{
    Detail,
    CreateFollow,
    TeacherAuth
  },
  methods:{
    typewriter(){
      clearTimeout(this.aiTimer);
      if (this.textIndex < this.aiText.length) {
        this.typeText += this.aiText.charAt(this.textIndex);
        this.textIndex++;
        this.$refs.aiText.scrollTop=this.$refs.aiText.scrollHeight;
        this.aiTimer = setTimeout(this.typewriter, 30);
      } else {
        clearTimeout(this.aiTimer);
      }
    },
    aiSearch(){
      this.aiLoading=true;
      this.closeSSE();
      this.eventSource=new EventSource(apiBase+`/user/teacher-ai-search?keyword=${this.aiKeyword}&access-token=${Cookies.get(this.constant.tokenName)}`);
      this.eventSource.onopen=()=>{
        console.log("SSE打开");
      }
      this.eventSource.onerror=()=>{
        this.eventSource.close();
        this.eventSource = null;
      }
      this.eventSource.addEventListener("result",this.handleResultEvent);
    },
    handleResultEvent(event){
      this.aiLoading=false;
      const eventData = JSON.parse(event.data);
      if (eventData.error?.length) {
        this.aiText = eventData.message;
      } else {
        this.aiText = eventData.output.text;
      }
    },
    closeSSE(){
      this.aiText="";
      this.typeText="";
      this.textIndex=0;
      clearTimeout(this.aiTimer);
      if(this.eventSource){
        this.eventSource.removeEventListener("result",this.handleResultEvent);
        this.eventSource.close();
        this.eventSource=null;
      }
    },
    showCert(data){
      this.visible = true;
      this.certificateUrl = data;
    },
    getCategoryTree(){
      this.api.course.categoryTree().then((res)=>{
        this.categoryList = res.tree;
        this.categoryList.push({
          child_id:'0|0|0',
          name:'暂无资质'
        })
      })
    },
    getPlaceList(){ //获取地域
      this.api.site.placeList().then((res)=>{
        this.cityList = res.list;
      })
    },
    teacherAuthClose(){
      this.getTeacherForm();
      this.teacherAuthShow=false;
    },
    downLoadFile(){
      window.open('https://public.saas.edu360.cn/template/teacher_import_v3.zip');
    },
    showExportModal(){
      this.exportFileModal = true;
    },
    showZipExportModal(){
      this.exportZipFileModal = true;
    },
    closeExportFileModal(){
      this.exportFileModal = false;
    },
    closeZipExportFileModal(){
      this.exportZipFileModal = false;
    },
    uploadFileSuccess(response){//导入成功
      this.uploadfileId = response.data.info.id;

      let params = {
        uploadfile_id:this.uploadfileId,
      }
      this.api.user.teacherImportResume(params).then((res)=>{
        this.$Message.success('上传成功');
        this.exportFileModal = false;

        this.curStatusIndex = 0;
        this.curTechDirectionIndex = 0;
        this.curTechSystemIndex = 0;
        this.curWorkExpIndex = 0;
        this.page = 1;

        this.getList();
      })
    },
    uploadZipFileSuccess(response){
      if(response.error){
        this.$Message.error(response.message);
      }else{
        this.uploadfileId = response.data.info.id;

        let params = {
          uploadfile_id:this.uploadfileId,
        }
        this.api.user.userTeacherImport(params).then((res)=>{
          this.$Message.success('上传成功');
          this.exportZipFileModal = false;

          this.curStatusIndex = 0;
          this.curTechDirectionIndex = 0;
          this.curTechSystemIndex = 0;
          this.curWorkExpIndex = 0;
          this.page = 1;

          this.getList();
        })
      }

    },

    changeSearch(index,name,data){
      console.log(data.id,'data.iddata.id')
      if(data.id == 99){
        this.is_blacklist = 1;
      }else{
        this.is_blacklist = 0;
      }
      this[name] = index;
      this.getList();

    },
    getTeacherForm(data){
      this.api.user.userTeacherForm().then((res)=>{
        this.techDirection = res.tech_direction;
        // this.techDirection = [{
        //   name:'全部',
        //   id:-1
        // }];
        // for(let name in res.tech_direction){
        //   this.techDirection.push({
        //     id:name,
        //     name:res.tech_direction[name]
        //   })
        // }
        this.techSystem = [];
        for(let name in res.tech_system){
          this.techSystem.push({
            id:name,
            name:res.tech_system[name]
          })
        }
        this.workExp = [];
        for(let name in res.work_exp){
          this.workExp.push({
            id:name,
            name:res.work_exp[name]
          })
        }
        // this.statusesArr = [{
        //   name:'全部',
        //   id:-1
        // }];
        this.statusesArr = [];
        for(let name in res.statuses){
          this.statusesArr.push({
            id:name,
            name:res.statuses[name],
          })
        }

        this.weixinSubscribes = [
          // {
          //   name:'全部',
          //   id:-1
          // }
        ];
        for(let name in res.weixin_subscribes){
          this.weixinSubscribes.push({
            id:name,
            name:res.weixin_subscribes[name],
          })
        }
        this.weixinSubscribesObj = res.weixin_subscribes;

        this.languageList = [];
        for(let name in res.language_abilities){
          this.languageList.push({
            id:name,
            name:res.language_abilities[name],
          })
        }

        this.timeList = [];
        for(let name in res.time_manages){
          this.timeList.push({
            id:name,
            name:res.time_manages[name],
          })
        }

        this.degreeList = [];
        for(let name in res.degrees){
          this.degreeList.push({
            id:name,
            name:res.degrees[name],
          })
        }

        this.activeTimeTypeArr = [];
        for(let name in res.activeTimeTypeArr){
          this.activeTimeTypeArr.push({
            id:name,
            name:res.activeTimeTypeArr[name],
          })
        }

        this.followTypes = [];
        for(let name in res.followTypes){
          this.followTypes.push({
            id:name,
            name:res.followTypes[name],
          })
        }

        this.getList();
      })
    },
    add(){
      // this.$router.push({
      //   path:'/user/authentication'
      // })
      this.teacherAuthShow=true;
    },
    check(userId,name){
      // this.$router.push({
      //   path:'/user/check-authentication',
      //   query:{
      //     id:userId,
      //     name:name
      //   }
      // })
      this.drawer = true;
    },
    edit(userId,name){
      console.log(name,'dddddd')
      this.$router.push({
        path:'/user/authentication',
        query:{
          id:userId,
          name:name
        }
      })
    },
    searchList(){
      this.page = 1;
      this.getList();
    },
    getList(){
      // this.techDirectionId
      // this.cityId
      // this.curCategory
      console.log(this.curCategory,'this.curCategory')
      let techDirection = '';
      if(this.techDirectionId.length){
        techDirection = this.techDirectionId.map((item)=>{
          return item.join('|')
        }).join(',');
      }


      // let city = this.cityId.map((item)=>{
      //   return item.join('|')
      // }).join(',');
      let category = '';
      if(this.curCategory.length){
        category = this.curCategory.map((item)=>{
          return item.join('|')
        }).join(',');
      }



      let params = {
        keyword:this.keyword,
        page:this.page,
        pageSize:this.pageSize,
        status:this.statusesArr[this.curStatusIndex].id,
        tech_direction:techDirection,
        tech_system:this.curTechSystem.join(','),
        work_exp:this.curWorkExp.join(','),
        degree:this.curDegree.join(','),
        time_manage:this.curTime.join(','),
        language_abilities:this.curLanguage.join(','),
        auth_category_item_ids:category,
        active_time_type:this.curActive,
        province_id:this.cityId[0],
        city_id:this.cityId[1] || '',
        weixin_subscribe:this.curWeixinSubscribe,
        is_blacklist:this.is_blacklist,
        // tech_direction:this.curTechDirection,
        // tech_system:this.techSystem[this.curTechSystemIndex].id,
        // work_exp:this.workExp[this.curWorkExpIndex].id,
      };
      if(this.is_blacklist == '1'){
        params.status = '-1';
      }

      this.api.user.userTeacherList(params).then((res)=>{
        if(!res.list.length && this.page > 1){
          this.page = this.page - 1;
          this.getList();
          return;
        }
        this.data = res.list;
        this.statuses = res.statuses;
        this.total = Number(res.count);


      })
    },
    changePage(page){
      this.page = page;
      this.getList();
    },
    changePageSize(pageSize){
      this.pageSize = pageSize;
      this.getList();
    },
    downLoad(data){
      // util.downloadFile(this,data);

      let arr = data.split('show/?file=');
      if(arr[1]){
          let uploadParams = {
            file:arr[1]
          };
          this.api.codekey.uploadfileDownload(uploadParams).then((res)=>{
            util.downLoad(res);
          })
      }else{
        let name = data.split('/');
        name = name[name.length -1].split(/\.(?=[^\.]+$)/);
        name = name[0] + '.' + name[1];
        FileSaver.saveAs(data,name);
      }

    },
    checkResume(data){
      window.open(data,'_blank')
    },
    showSwiper(){
      this.$nextTick(()=>{
        this.swiper = new Swiper('.swiper-container', {
          pagination: '.swiper-pagination',
          loop: true, // 循环
          spaceBetween: 20, // swiper-slide 间的距离为0
          autoplay: 2000, // 自动切换时间间隔
          speed: 1000, // 滑动速度
          prevButton: '.swiper-button-prev',
          nextButton: '.swiper-button-next',
          paginationClickable:true,
        })
      })
    },
    closeAuthCertModal(){
      this.authCertModal = false
    },
    teacherVerify(id,status){
      let params = {
        user_id:id,
        status:status
      };
      if(this.verifyReason){
        params.refuse_reason = this.verifyReason;
      }
      this.api.user.userTeacherVerify(params).then((res)=>{
        this.$Message.success('操作成功');
        this.verifyReasonModal = false;
        this.getList();
      })
    },
    confirmVerifyReason(){
      this.teacherVerify(this.curData.user_id,2)
    },
    closeVerifyReason(){
      this.verifyReason = '';
      this.verifyReasonModal = false;
    },
    goStatistics(){
      this.$router.push({
        path:'/user/statistics',
      })
    },
    goNotice(){
      this.$router.push({
        path:'/user/teacher-notice',
      })
    },
    deleSuccess(){
      this.drawer = false;
      this.getList();
    },
    opSuccess(){
      this.getList();
    },
    follow(){
      this.createFollowStatus = true;
    },
    followSuccess(){
      this.createFollowStatus = false;
      this.getList();
    },
    followClose(){
      this.createFollowStatus = false;
    },
    invite(){
      this.inviteModal = true;
      this.api.user.teacherInvitationFillInfo().then((res)=>{
        this.inviteUrl = res.info.url;
        this.inviteImg = res.info.poster;
      })
    },
    doCopy(data){
      this.$copyText(data).then( (e)=>{
        this.$Message.success('复制成功');
      }, (e)=> {
        this.$Message.success('复制失败');
      })
    },
    exportImg() {
      let image = new Image()
      image.setAttribute('crossOrigin', 'anonymous')
      image.src = this.inviteImg
      image.onload = () => {
        let canvas = document.createElement('canvas')
        canvas.width = image.width
        canvas.height = image.height
        let ctx = canvas.getContext('2d')
        ctx.drawImage(image, 0, 0, image.width, image.height)
        canvas.toBlob((blob) => {
          let url = URL.createObjectURL(blob)
          this.download(url,'邀请海报')
          // 用完释放URL对象
          URL.revokeObjectURL(url)
        })
      }
    },
    // download(href, name) {
    //
    //
    //   console.log(href,'hreffffffppppppppp')
    //
    //   // let uploadParams = {
    //   //   file:res.filepath
    //   // };
    //   // this.api.codekey.uploadfileDownload(uploadParams).then((res)=>{
    //   //   util.downLoad(res);
    //   //   this.exportNamesModal = false;
    //   // })
    //
    //
    //
    //   let eleLink = document.createElement('a')
    //   eleLink.download = name
    //   eleLink.href = href
    //   eleLink.click()
    //   eleLink.remove()
    // },
    removeBlackList(){
      let params = {
        id:this.curData.id
      };
      this.api.user.teacherSetWhitelist(params).then((res)=>{
        this.$Message.success('移除成功')
        this.getList();
      })
    },
    closeDrawer(){
      this.drawer = false;
    },
    setTop(data){
      this.curData = data;
      let params = {
        id:this.curData.id
      };
      this.api.user.teacherSetTop(params).then((res)=>{
        this.$Message.success('置顶成功');
        this.page = 1;
        this.getList();
      })
    },
    cancelTop(data){
      let params = {
        id:this.curData.id
      };
      this.api.user.teacherCancelTop(params).then((res)=>{
        this.$Message.success('取消置顶成功');
        this.getList();
      })
    }
  },
  watch:{
    aiText(){
      this.typewriter();
    }
  },
}
</script>

<style scoped lang="scss">
.ai-tips{
  padding: 20px;
  font-size:18px;
  display: flex;
  align-items:center;
  .el-button{
    margin-left: 20px;
  }
}
.ai-search{
  display: flex;
  align-items: center;
  padding: 20px;
  .el-button{
    margin-left: 20px;
  }
}
.ai-text{
  padding: 20px;
  overflow-y: auto;
  font-size: 16px;
  white-space:pre-wrap;
  height: calc(100vh - 250px);
  overflow: auto;
}
.authentication-verify{
  margin: 20px;
  padding:20px;
  background: #FFFFFF;
  box-shadow: 0px 2px 12px 0px rgba(189,189,189,0.4100);
  border-radius: 4px;
  font-size: 14px;

  .top{
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .teacher-tab{
    margin-top: 30px;
    height: 34px;
    font-size: 16px;
    color: #666666;
    border-bottom: 1px solid #E1E1E1;
    display: flex;
    justify-content: flex-start;
    >div{
      margin-right: 40px;
      height: 100%;
      cursor: pointer;
    }
    .active{
      font-weight: bold;
      color: #4A79FF;
      border-bottom: 4px solid #5782FF;
    }
  }
  .search{
    margin-top: 20px;
    margin-bottom: 20px;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    .search-item{
      margin-bottom: 15px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      font-size: 14px;

      .name{
        padding: 5px 0;
        width: 85px;
        text-align: right;
      }
      .cont{
        margin-left: 15px;
        flex: 1;
        display: flex;
        justify-content: space-between;

        .values{
          display: flex;
          justify-content: flex-start;
          flex-flow: wrap;
          transition: max-height 0.3s linear;
          overflow: hidden;

          >div{
            padding: 5px 10px;
            //margin: 0 10px 10px 5px;
            cursor: pointer;
          }
          >div.active{
            background-color: #2d8cf0;
            color:#FFFFFF;
            border-radius: 4px;
          }
        }
        .more{
          padding: 5px 0;
          width: 80px;
          text-align: right;
          cursor: pointer;
          .more-icon{
            transition: transform 0.3s linear;
          }
        }
      }
    }
  }
  .page{
    margin-top: 20px;
    text-align: right;
  }
}
.upload-cont-info-message{
  padding-top: 20px;
  margin: 0 auto 10px auto;
  width: 300px;
  text-align: left;
  line-height: 24px;
  .down-load{
    color:#2d8cf0;

  }
}
.pc-url{
  padding: 20px;
  background: #F6F7FA;
  border-radius: 6px;
  font-size: 14px;
}
</style>
<style>
/*.el-cascader-menu{*/
/*  height: 200px !important;*/
/*}*/
::v-deep.el-drawer__wrapper {
  z-index: 100!important;
}
.ivu-message{
  z-index: 3000!important;
}
.teacher-list.ivu-tooltip{
  width: 100%;
}
.teacher-list .ivu-tooltip-rel{
  overflow: hidden!important;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
</style>
